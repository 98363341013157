import React from "react";
import { getIn } from 'formik'
import ReactPhoneInput from 'react-phone-input-mui'
import { fieldToTextField } from 'formik-material-ui'
import { TextField, withStyles } from '@material-ui/core'
import 'react-phone-input-mui/dist/style.css'

const styles = theme => ({
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
  dropdownButton: {
    '& .selected-flag .flag': {
      marginTop: '1px',
    }
  },
})

function FormikPhoneInput(props) {
  const {
    form: { setFieldValue },
    field: { name },
    classes
  } = props;
  const onChange = React.useCallback(
    number => {
      setFieldValue(name, number);
    },
    [setFieldValue, name]
  );
  return <ReactPhoneInput 
    inputClass={classes.field}
    dropdownClass={classes.countryList}
    component={TextField}
    defaultCountry={'za'}
    regions={'africa'}
    {...fieldToTextField(props)}
    buttonClass={classes.dropdownButton}
    onChange={onChange}
    masks={{za: '+.. .. ... ....'}}
    isValid={(value, country) => {
      const fieldError = getIn(props.form.errors, props.field.name)
      const touched = getIn(props.form.touched, props.field.name)
      return !(fieldError && touched)
    }}
  />;
}

export default withStyles(styles)(FormikPhoneInput);