import * as React from 'react';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import {
  ClickAwayListener,
  IconButton,
  FormHelperText
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import { TextField } from 'formik-material-ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import FormikPhoneInput from 'components/Misc/FormikPhoneInput'
import { useSnackbar } from 'notistack';

import * as Yup from 'yup';

import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { useMutation, useQuery } from '@apollo/client';
import { CREATE_OR_MODIFY_ORG, ORG_QUERY } from 'gql/queries'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.black,
  },
}));

const NewOrganisationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  address: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  province: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  country: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  contactEmail: Yup.string()
    .email('Invalid email'),
  contactName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!'),
  mobileNumber: Yup.mixed().test({
    message: 'Invalid mobile',
    test: (value) => {
      if (!value) return false
      const phoneNumber = parsePhoneNumberFromString(value)
      if (!phoneNumber) return false
      return phoneNumber.isValid()
    },
  }).required('Required'),
});


const AddOrganisationForm = function AddOrganisationForm ({
  onCloseForm,
  entityId
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [createOrModifyOrganisation] = useMutation(CREATE_OR_MODIFY_ORG);
  const { loading: loadingEntity, error: entityError, data: entityQuery } = useQuery(ORG_QUERY,
    {
      variables: {
        id: entityId
      },
    })

  const handleClickAway = () => {
    onCloseForm(false)
  }

  if (loadingEntity)
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <p>Loading...</p>
        </ClickAwayListener>
      </Dialog>
    )

  if (entityError)
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <p>Error...</p>
        </ClickAwayListener>
      </Dialog>
    )

  const organisation = entityQuery?.organisation

  return(
    <Formik
      initialValues={{
        id: organisation ? entityId : '',
        name: organisation ? organisation.name : '',
        address: organisation ? organisation.address : '',
        province: organisation ? organisation.province : '',
        country: organisation ? organisation.country : '',
        mobileNumber: organisation ? organisation.mobileNumber || '' : '',
        contactName: organisation ? organisation.contactName || '' : '',
        contactEmail: organisation ? organisation.contactEmail || '' : '',
      }}
      validationSchema={NewOrganisationSchema}
      onSubmit={(values, { setSubmitting }) => {
        createOrModifyOrganisation({
          variables: {
            data: values
          }
        })
        .then( () => {
          onCloseForm(true)
        })
        .catch( (error) => {
          enqueueSnackbar(error.message, { 
            variant: 'error',
          });
        })
        .finally( () => {
          setSubmitting(false)
        })
        
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <div>
          <Form>
            <Dialog open={true} aria-labelledby="form-dialog-title">
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <DialogTitle id="form-dialog-title">
                    {organisation ? 'Modify' : 'Add'} Organisation
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClickAway}>
                      <CloseIcon />
                    </IconButton>
                    </DialogTitle>
                  <DialogContent>
                  <DialogContentText>
                  {organisation ? 'Modify an Organisation on' : 'Add an Organisation to'} Tip4Change
                  </DialogContentText>
                    <Field
                      component={TextField}
                      name="name"
                      type="string"
                      label="Organisation Name"
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      name="address"
                      type="string"
                      label="Address"
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      name="province"
                      type="string"
                      label="Province"
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      name="country"
                      type="string"
                      label="Country"
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      name="contactName"
                      type="string"
                      label="Contact Name"
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      name="contactEmail"
                      type="email"
                      label="Contact Email"
                      fullWidth
                    />
                    <Field
                      component={FormikPhoneInput}
                      name="mobileNumber"
                      type="string"
                      inputExtraProps={{
                        name: 'mobileNumber',
                        label: "Mobile Number (Required)"
                      }}
                    />
                    <ErrorMessage component={FormHelperText} error={true} name="mobileNumber" />
                  </DialogContent>
                  <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {organisation ? 'MODIFY ORGANISATION' : 'ADD ORGANISATION'}
                  </Button>
                  </DialogActions>
                </div>
              </ClickAwayListener>
            </Dialog>
          </Form>
        </div>
      )}
    </Formik>
  );
}

AddOrganisationForm.propTypes = {
  onCloseForm: PropTypes.func.isRequired,
  entityId: PropTypes.string,
};

export default AddOrganisationForm;