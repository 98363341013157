import React from "react";
import { Container, Card, CardActions, CardContent, Typography }  from '@material-ui/core'
import {
  t4cBlueColor,
} from 'assets/jss/material-dashboard-react.js'
import Button from 'components/CustomButtons/Button.js'
import StdButton from "@material-ui/core/Button";
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import { userSignInMutation } from 'gql/queries'
import { useMutation } from '@apollo/client'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { setToken } from 'services/auth'
import ForgotPassword from './ForgotPassword'

const useStyles = makeStyles({
  root: {
    backgroundColor: t4cBlueColor
  },
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rightButton: {
    marginLeft: 'auto',
  }
});

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(300, 'Too Long!')
    .required('Required'),
})

export default function Login() {

  const classes = useStyles();

  const history = useHistory();

  const [ signIn ] = useMutation(
    userSignInMutation,
  )

  const { enqueueSnackbar } = useSnackbar();

  const [showForgotPassword, setShowForgotPassword] = React.useState(false)

  const toggleShowForgotPasswordForm = () => {
    setShowForgotPassword((showForgotPassword) => {
      return !showForgotPassword
    })
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Card elevation={0} >
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, { setSubmitting }) => {
              signIn({
                variables: {
                  ...values
                },
              })
              .then((result) => {
                setToken(result.data.userSignIn.token)
                history.push("/admin");
              })
              .catch((error) => {
                setSubmitting(false)
                enqueueSnackbar(error.message, { 
                  variant: 'error',
                });
              })
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Login to the Tip4Change Administrator Portal
                    </Typography>
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      label="Email"
                      color="secondary"
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      name="password"
                      type="password"
                      label="Password"
                      color="secondary"
                      fullWidth
                    />
                  </CardContent>
                  <CardActions disableSpacing>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Login
                    </Button>
                    <StdButton
                      className={classes.rightButton}
                      color="secondary"
                      disabled={isSubmitting}
                      onClick={toggleShowForgotPasswordForm}
                    >
                      Forgot Password?
                    </StdButton>
                  </CardActions>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
      {showForgotPassword && <ForgotPassword onCloseForm={toggleShowForgotPasswordForm}/>}
    </div>
  );
}
