import React from "react"
import { tippeeWithAccessCodeQuery, TIPPEE_DO_PAYOUT_MUTATION } from 'gql/queries'
import { makeStyles } from '@material-ui/core/styles';
import MobileOptimisedContainer from 'components/Layout/MobileOptimisedContainer'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  IconButton,
  Tooltip,
  SvgIcon,
  ClickAwayListener,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as QrCodeIcon } from 'assets/img/qr_code-24px.svg'
import { useParams } from "react-router-dom"
import { useQuery, useMutation } from '@apollo/client'
import TippeeHeader from 'components/Misc/TippeeHeader.js'
import { useSnackbar } from 'notistack';
import ChangePayoutForm from './ChangePayoutForm'
import { QRCode } from 'react-qrcode-logo'
import logo from "assets/img/Tip4Change_Logo_BW-02.png"

const QR_SIZE = 200

const useStyles = makeStyles((theme) => ({
  tippeeHeader: {
    padding: '16px'
  },
  balance: {
    fontWeight: '400',
    fontSize: '26px',
  },
  lowerSection: {
    padding: '16px',
    color: '#000000',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    backgroundColor: 'white',
  },
  table: {
    fontSize: '18px',
    textAlign: 'left',
    '& td': {
      paddingRight: '5px',
      paddingLeft: '5px'
    }
  },
  button: {
    width: '200px',
  },
  buttonContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '@media (min-width: 600px)': {
      minHeight: '180px'
    },
  },
  leftHeaderButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.black,
  },
  rightHeaderButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.black,
  },
}))

export default function Tippee() {

  const classes = useStyles();

  const { accessCode } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const [tippeeDoPayout] = useMutation(TIPPEE_DO_PAYOUT_MUTATION)

  const { loading, error, data, refetch } = useQuery(tippeeWithAccessCodeQuery,
    {
      variables: {
        accessCode: accessCode
      },
    })

  const [showPayoutConfirm, setShowPayoutConfirm] = React.useState(false)
  const [showQrCode, setShowQrCode] = React.useState(false)
  const [showChangePayout, setShowChangePayout] = React.useState(false)
  const [payingOut, setPayingOut] = React.useState(false)

  const togglePayoutConfirm = () => {
    setShowPayoutConfirm((showPayoutConfirm) => {
      return !showPayoutConfirm
    })
  }

  const toggleShowQrCode= () => {
    setShowQrCode((showQrCode) => {
      return !showQrCode
    })
  }

  const toggleShowPayoutForm = () => {
    setShowChangePayout((showChangePayout) => {
      return !showChangePayout
    })
  }

  if (loading) 
    return (
      <div>Loading</div>
    )

  if (error) 
    return (
      <div>Error</div>
    )

  const tippee = data.tippeeWithAccessCode

  const requestPayout = () => {
    setPayingOut(true)
    tippeeDoPayout({
      variables: {
        data: {
          accessCode: accessCode,
          tippeeId: tippee.id
        },
      },
    })
    .then(() => {
      refetch()
      togglePayoutConfirm()
      enqueueSnackbar('Payout successful', { 
        variant: 'success',
      });
    })
    .catch((error) => {
      enqueueSnackbar(error.message, { 
        variant: 'error',
      });
    })
    .finally(() => {
      setPayingOut(false)
    })
  }

  return (

    <MobileOptimisedContainer className={classes.container}>
      <Tooltip title="Close">
        <IconButton aria-label="Show Qr Code" className={classes.leftHeaderButton} onClick={toggleShowQrCode}>
          <SvgIcon component={QrCodeIcon}/>
        </IconButton>
      </Tooltip>
      <TippeeHeader className={classes.tippeeHeader} tippee={tippee}/>
      <div className={classes.lowerSection}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td>Balance</td>
              <td>R{(tippee.balance/100).toFixed(2)}</td>
            </tr>
            <tr>
              <td>ID Number</td>
              <td>{tippee.idNumber}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{tippee.email}</td>
            </tr>
            <tr>
              <td>Mobile Number</td>
              <td>{tippee.mobileNumber}</td>
            </tr>
            <tr>
              <td>Payout Method</td>
              <td>{tippee.payoutProcessorName}</td>
            </tr>
          </tbody>
        </table>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            disabled={tippee.balance <= 0}
            onClick={togglePayoutConfirm}
            className={classes.button}
          >
            Payout
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleShowPayoutForm}
            className={classes.button}
          >
            Change Payout Method
          </Button>
          <Button
            variant="contained"
            color="primary"
            href={`/tippee/${accessCode}/transactions`}
            className={classes.button}
          >
            Transactions
          </Button>
        </div>
        <Dialog
          open={showPayoutConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Would like to pay out your balance of R{(tippee.balance/100).toFixed(2)} via {tippee.payoutProcessor}
            </DialogContentText>
          </DialogContent>
          {payingOut && <LinearProgress />}
          <DialogActions>
            <Button onClick={togglePayoutConfirm} color="secondary" disabled={payingOut}>
              Cancel
            </Button>
            <Button onClick={requestPayout} color="secondary" autoFocus disabled={payingOut}>
              {payingOut ? "Paying Out..." : "Payout"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showQrCode}
          aria-labelledby="alert-dialog-title"
        >
          <ClickAwayListener onClickAway={toggleShowQrCode}>
            <div>
              <DialogTitle id="alert-dialog-title">
                {"Scan QR Code to Tip"}
                <IconButton aria-label="close" className={classes.rightHeaderButton} onClick={toggleShowQrCode}>
                  <CloseIcon />
                </IconButton>
                </DialogTitle>
              <DialogContent>
                <QRCode value={tippee.qrCode} logoImage={logo} size={QR_SIZE} logoWidth={QR_SIZE * 0.21}/>
              </DialogContent>
            </div>
          </ClickAwayListener>
        </Dialog>
      </div>
      {showChangePayout && <ChangePayoutForm accessCode={accessCode} tippee={tippee} onCloseForm={toggleShowPayoutForm}/>}
    </MobileOptimisedContainer>
  );
}
