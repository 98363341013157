import React from "react";
import { useQuery, useMutation } from '@apollo/client'
import { Container, Card, CardActions, CardContent, Typography, IconButton, InputAdornment, CircularProgress }  from '@material-ui/core'
import {
  t4cBlueColor,
} from 'assets/jss/material-dashboard-react.js'
import Button from 'components/CustomButtons/Button.js'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import { useHistory } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import { setToken } from 'services/auth'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useParams } from "react-router-dom";
import { USER_WITH_ACCESS_CODE_QUERY, userPasswordChangeMutation } from 'gql/queries'

const useStyles = makeStyles({
  root: {
    backgroundColor: t4cBlueColor
  },
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  field: {
    fontSize: '22px',
    lineHeight: '24px',
  }
});

const FormValidation = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(300, 'Too Long!')
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
})

export default function ChangePassword() {

  const classes = useStyles();

  const history = useHistory();

  const { accessCode } = useParams();

  const { loading, error, data } = useQuery(USER_WITH_ACCESS_CODE_QUERY,
    {
      variables: {
        accessCode: accessCode
      },
    })
  const [ userPasswordChange ] = useMutation(userPasswordChangeMutation)

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => {
      return !showPassword
    })
  };

  const handleClickShowPasswordConfirmation = () => {
    setShowPasswordConfirmation((showPasswordConfirmation) => {
      return !showPasswordConfirmation
    })
  };

  const { enqueueSnackbar } = useSnackbar();

  if (loading) return <p>Loading...</p>
  if (error) return <p>Link expired, please request another link</p>

  const user = data.userWithAccessCode

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Card elevation={0} >
          <Formik
            initialValues={{
              email: user.email,
              password: '',
              passwordConfirmation: '',
            }}
            validationSchema={FormValidation}
            onSubmit={(values, { setSubmitting }) => {
              const { password } = values
              userPasswordChange({
                variables: {
                  data: {
                    password,
                    accessCode
                  },
                }
              })
              .then((result) => {
                setToken(result.data.userPasswordChange.token)
                history.push("/admin");
              })
              .catch((error) => {
                setSubmitting(false)
                enqueueSnackbar(error.message, { 
                  variant: 'error',
                });
              })
            }}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form>
                <div>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Create/Change your Tip4Change Admin Password
                    </Typography>
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      label="Email"
                      color="secondary"
                      fullWidth
                      disabled
                    />
                    <Field
                      component={TextField}
                      name="password"
                      label="Password"
                      fullWidth
                      color="secondary"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        className: classes.field
                      }}
                    />
                    <Field
                      component={TextField}
                      name="passwordConfirmation"
                      label="Confirm Password"
                      fullWidth
                      color="secondary"
                      type={showPasswordConfirmation ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordConfirmation}
                            >
                              {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        className: classes.field
                      }}
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      startIcon={isSubmitting && <CircularProgress className={classes.spinner} size='36px' disabled={!(isValid && dirty)}/>}
                    >
                      <div>
                        {isSubmitting ? 'Setting new password' : 'Set new password'}
                      </div>
                    </Button>
                  </CardActions>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </div>
  );
}
