import React from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import moment from 'moment'
import { useQuery } from '@apollo/client'
import { TOTAL_FEES, TOTAL_PAYMENTS, LATEST_TIPPEES } from 'gql/queries'

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {

  const startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
  const tzOffset = (new Date()).getTimezoneOffset()


  const { data: fees } = useQuery(TOTAL_FEES,
    {
      variables: {
        tz: tzOffset,
        startDate: startDate
      },
    })

  const { data: payments } = useQuery(TOTAL_PAYMENTS,
    {
      variables: {
        tz: tzOffset,
        startDate: startDate
      },
    })

  const { data: tippees } = useQuery(LATEST_TIPPEES)

  const tippeeStats = tippees ? tippees.latestTippees.map((tippee) => {
    return [
      `${tippee.firstName} ${tippee.lastName}`,
      tippee.location.name,
      moment(tippee.createDateTime).format('YYYY-MM-DD')
    ]
  }) : []
  
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <Icon>attach_money</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Service Fee Revenue</p>
              <h3 className={classes.cardTitle}>R{ fees && (fees.totalFees/100).toFixed(2) }</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 7 Days
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
              <Icon>attach_money</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Gross Tip Revenue</p>
              <h3 className={classes.cardTitle}>R{ payments && (payments.totalPayments/100).toFixed(2) }</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 7 Days
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleBlack}>Tippee Stats</h4>
              <p className={classes.cardCategoryBlack}>
                Newest Onboarded Tippees
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Name", "Location", "Joined"]}
                tableData={tippeeStats}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
