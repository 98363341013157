import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import PaginatedTable from "components/Table/PaginatedTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Add from "@material-ui/icons/Add";
import { useQuery } from '@apollo/client';
import Edit from '@material-ui/icons/Edit'
import {
  cardCategoryBlack,
  cardTitleBlack,
  headerButtonRight,
  tableHeadButton,
  tableHeadButtonText,
  tableCard,
  tableCardBody,
} from 'assets/jss/tip4change-styles.js'

import AddLocationForm from "./AddLocationForm"
import { LOCATIONS_QUERY } from 'gql/queries'

const styles = {
  cardCategoryBlack,
  cardTitleBlack,
  headerButtonRight,
  tableHeadButton,
  tableHeadButtonText,
  tableCard,
  tableCardBody,
};

const useStyles = makeStyles(styles);

export default function LocationsManage() {
  const classes = useStyles();
  const { loading, error, data, refetch } = useQuery(LOCATIONS_QUERY);
  const [showForm, setShowForm] = React.useState(false);
  const [entityId, setEntityId] = React.useState('')

  const openForm = (id) => {
    setEntityId(id)
    setShowForm(true)
  }

  const locationAdded = (newData) => {
    setShowForm(false)
    if (newData) refetch()
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const locations =  data.locations.map((location) => {
    return [
      location.name,
      location.province,
      location.contactName,
      `${location.mobileNumber.slice(0,3)} ${location.mobileNumber.slice(3,5)} ${location.mobileNumber.slice(5,8)} ${location.mobileNumber.slice(8,12)}`,
      location.contactEmail,
      location.tippeeCount,
      location.organisation ? location.organisation.name: 'unassigned',
      {
        type: 'icon-button',
        handler: openForm,
        id: location.id,
        child: (
          <React.Fragment>
            <Edit />
          </React.Fragment>
        ),
      },
    ]
  })

  return (
    <React.Fragment>
      {showForm && <AddLocationForm onCloseForm={locationAdded} entityId={entityId} />}
      <Card className={classes.tableCard}>
        <CardHeader color="primary">
          <GridContainer>
            <GridItem xs={8} sm={8} md={8}>
              <h4 className={classes.cardTitleBlack}>Locations</h4>
              <p className={classes.cardCategoryBlack}>
                All of the locations in your organisation
              </p>
            </GridItem>
            <GridItem xs={4} sm={4} md={4} classes={{root: classes.headerButtonRight}}>
              <Button className={classes.tableHeadButton}color="primary" onClick={() => openForm('')}>
                <div className={classes.tableHeadButtonText}>Add Location </div>
                <Add />
              </Button>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody className={classes.tableCardBody}>
          <PaginatedTable
            tableHeaderColor="gray"
            tableHead={["Name", "Province", "Contact Name", "Mobile", "Email", "# Tippees", "Organisation",""]}
            tableData={locations}
          />
        </CardBody>
      </Card>
    </React.Fragment>
    
  );
}
