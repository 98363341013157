import { useQuery, useMutation } from '@apollo/client'
import {
  ClickAwayListener,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Button,
} from '@material-ui/core'
import {
  t4cBlueColor,
  t4cGreenColor,
  t4cOrangeColor,
  t4cPurpleColor,
  t4cRedColor
} from 'assets/jss/material-dashboard-react.js'
import {
  coloredCellText,
} from 'assets/jss/tip4change-styles.js'
import CloseIcon from '@material-ui/icons/Close';
import SmsIcon from '@material-ui/icons/Sms';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import * as React from 'react'
import { QRCode } from 'react-qrcode-logo';
import logo from "assets/img/Tip4Change_Logo_BW-02.png";
import PaginatedTable from 'components/Table/PaginatedTable.js'
import moment from 'moment'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import TippeeHeader from 'components/Misc/TippeeHeader.js'
import { TIPPEE_QUERY, SEND_TIPPEE_SMS } from 'gql/queries'
import { useSnackbar } from 'notistack';
import { getAuthToken, sessionUser } from 'services/auth'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "'Betm', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: '#000000',
  },
  contents: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    color: '#000000',
    textAlign: 'center'
  },
  
  tipActions: {
    flexBasis: "60%",
    display: 'flex',
    flexDirection: 'column'
  },
  spaceFiller: {
    flexGrow: 1
  },
  dialogTitle: {
    backgroundColor: t4cBlueColor,
  },
  cardTitle: {
    fontWeight: '400',
    fontSize: '22px',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  balance: {
    fontWeight: '400',
    fontSize: '26px',
  },
  links: {
    fontWeight: '400',
    fontSize: '26px',
    color: `${t4cBlueColor} !important`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.black,
  },
  smsButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.black,
  },
  coloredCellText,
  buttonHolder: {
    '& > *': {
      margin: theme.spacing(1),
    }
  }
}));

const QR_SIZE = 200

const ViewTippee = function ViewTippee({ onCloseForm, tippeeId, profileClickFunction, toggleFileManager }) {

  const token = getAuthToken()
  const user = sessionUser(token)

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [sendTippeeSMS] = useMutation(SEND_TIPPEE_SMS)
  const { loading, error, data, } = useQuery(TIPPEE_QUERY,
    {
      variables: { id: tippeeId },
      pollInterval: 10000
    })

  const [sendingSMS, setSendingSMS] = React.useState(false)

  const handleClickAway = () => {
    onCloseForm()
  }

  const triggerSendTippeeSMS = (id) => {
    setSendingSMS(true)
    sendTippeeSMS({
      variables: {
        id
      }
    })
    .then(() => {
      enqueueSnackbar('SMS sent successfully', { 
        variant: 'success',
      });
    })
    .catch((error) => {
      enqueueSnackbar(error.message, { 
        variant: 'error',
      });
    })
    .finally(() => {
      setSendingSMS(false)
    })
  }

  if (loading)
    return (
      <Dialog open={true} aria-labelledby="tippee-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <div/>
        </ClickAwayListener>
      </Dialog>
    )

  if (error)
    return (
      <Dialog open={true} aria-labelledby="tippee-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <p>Error...</p>
        </ClickAwayListener>
      </Dialog>
    )

  function compare(a, b) {
    return a.timestamp > b.timestamp ? -1 : 1;
  }

  function rowColor(type) {
    switch (type) {
      case 'Payout':
        return t4cOrangeColor+'7D'
      case 'Payment':
        return t4cGreenColor+'7D'
      case 'Fee':
        return t4cRedColor+'7D'
      default:
        return t4cPurpleColor+'7D'
    }
  }

  data.tippee.transactions.sort(compare);

  const transactions = data.tippee.transactions.map((trans) => {
    return [
      'R'+(trans.totalInCents/100).toFixed(2),
      {
        type: 'txType',
        value: trans.type,
        color: rowColor(trans.type),
        class: classes.coloredCellText
      },
      moment(trans.timestamp).format('LLL'),
      trans.provider,
      trans.description,
    ]
  })

  return (
    <Dialog open={true} aria-labelledby="tippee-dialog-title" className={classes.root} maxWidth='md'>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <DialogTitle id="tippee-dialog-title" className={classes.dialogTitle}>
            <TippeeHeader tippee={data.tippee} profileClickFunction={profileClickFunction}/>
            <Tooltip title="Close">
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleClickAway}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
            {user.role === 'ADMIN' && <Tooltip title="Send Welcome SMS">
              <IconButton aria-label="Send Welcome SMS" className={classes.smsButton} disabled={sendingSMS} onClick={() => triggerSendTippeeSMS(data.tippee.id)}>
                {sendingSMS ? <CircularProgress size={24} /> : <SmsIcon />}
              </IconButton>
            </Tooltip>}
          </DialogTitle>
          <DialogContent className={classes.contents}>
            {data.tippee.qrCode && (
              <div>
                <QRCode value={data.tippee.qrCode} logoImage={logo} size={QR_SIZE} logoWidth={QR_SIZE * 0.21}/>
              </div>
            )}
            <div className={classes.balance}>Balance: R{(data.tippee.balance/100).toFixed(2)}</div>
            <div className={classes.buttonHolder}>
              <Button variant="contained" rel="noopener noreferrer" target="_blank" color="primary" href={data.tippee.tippeeAccessLink}>
                Tippee Link
              </Button>
              <Button variant="contained" rel="noopener noreferrer" target="_blank" color="primary" href={`${process.env.REACT_APP_T4C_API_ADDR}/tippee/lanyard?id=${data.tippee.id}`}>
                Lanyard PDF
              </Button>
              <Button variant="contained" color="primary" onClick={() => toggleFileManager()}>
                File Manager
              </Button>
            </div>
            <div className={classes.tableContainer}>
              <Card>
                <CardHeader color="primary" className={classes.cardTitle}>
                  Transactions
                </CardHeader>
                <CardBody>
                  <PaginatedTable
                    tableHead={[
                      'Amount',
                      'Type',
                      'Timestamp',
                      'Provider',
                      'Description/ID/Token',
                    ]}
                    tableData={transactions}
                  />
                </CardBody>
              </Card>
            </div>
          </DialogContent>
        </div>
      </ClickAwayListener>
    </Dialog>
  )
}

ViewTippee.propTypes = {
  onCloseForm: PropTypes.func.isRequired,
  tippeeId: PropTypes.string.isRequired,
  profileClickFunction: PropTypes.func.isRequired,
}

export default ViewTippee
