import { useQuery } from '@apollo/client'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Edit from '@material-ui/icons/Edit'
// core components
import GridItem from 'components/Grid/GridItem.js'
import PaginatedTable from 'components/Table/PaginatedTable.js'
import React from 'react'

import {
  cardCategoryBlack,
  cardTitleBlack,
  headerButtonRight,
  tableHeadButton,
  tableHeadButtonText,
  tableCard,
  tableCardBody,
} from 'assets/jss/tip4change-styles.js'

import AddOrganisationForm from './AddOrganisationForm'
import { ORGS_QUERY } from 'gql/queries'

const styles = {
  cardCategoryBlack,
  cardTitleBlack,
  headerButtonRight,
  tableHeadButton,
  tableHeadButtonText,
  tableCard,
  tableCardBody,
}

const useStyles = makeStyles(styles)

export default function OrganisationsManage() {
  const classes = useStyles()
  const { loading, error, data, refetch } = useQuery(ORGS_QUERY)
  const [showForm, setShowForm] = React.useState(false)
  const [entityId, setEntityId] = React.useState('')

  const openForm = (id) => {
    setEntityId(id)
    setShowForm(true)
  }

  const organisationAdded = (newData) => {
    setShowForm(false)
    if (newData) refetch()
  }

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  const organisations = data.organisations.map((organisation) => {
    return [
      organisation.name,
      organisation.province,
      organisation.contactName,
      `${organisation.mobileNumber.slice(0,3)} ${organisation.mobileNumber.slice(3,5)} ${organisation.mobileNumber.slice(5,8)} ${organisation.mobileNumber.slice(8,12)}`,
      organisation.contactEmail,
      organisation.locationCount,
      organisation.tippeeCount,
      {
        type: 'icon-button',
        handler: openForm,
        id: organisation.id,
        child: (
          <React.Fragment>
            <Edit />
          </React.Fragment>
        ),
      },
    ]
  })

  return (
    <React.Fragment>
      {showForm && <AddOrganisationForm onCloseForm={organisationAdded} entityId={entityId} />}
      <Card className={classes.tableCard}>
        <CardHeader color="primary">
          <GridContainer>
            <GridItem xs={8} sm={8} md={8}>
              <h4 className={classes.cardTitleBlack}>Organisations</h4>
              <p className={classes.cardCategoryBlack}>
                All of the organisations within Tip4Change
              </p>
            </GridItem>
            <GridItem
              xs={4}
              sm={4}
              md={4}
              classes={{ root: classes.headerButtonRight }}
            >
              <Button className={classes.tableHeadButton}color="primary" onClick={() => openForm('')}>
                <div className={classes.tableHeadButtonText}>Add Organisation </div>
                <Add />
              </Button>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody className={classes.tableCardBody}>
          <PaginatedTable
            tableHeaderColor="gray"
            tableHead={['Name', 'Province', 'Contact Name', 'Mobile', 'Email', '# Locations', '# Tippees','']}
            tableData={organisations}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
