import { useMutation, useQuery } from '@apollo/client'
import {
  ClickAwayListener,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'components/CustomButtons/Button.js'
import { Field, Form, Formik } from 'formik'
import { Select } from 'formik-material-ui'
import PropTypes from 'prop-types'
import * as React from 'react'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack';
import { PROVIDER_QUERY, TIPPEE_CHANGE_PAYOUT_PROVIDER_MUTATION } from 'gql/queries'

const DoPayoutForm = function DoPayoutForm({
  onCloseForm,
  tippee,
  accessCode
}) {
  const [tippeeChangePayoutProvider] = useMutation(TIPPEE_CHANGE_PAYOUT_PROVIDER_MUTATION)

  const { loading: loadingProviders, error: providerError, data: providers } = useQuery(PROVIDER_QUERY,
    {
      variables: { id: tippee.id },
    })

  const { enqueueSnackbar } = useSnackbar();

  const handleClickAway = () => {
    onCloseForm()
  }

  const DoPayoutSchema = Yup.object().shape({
    accessCode: Yup.string()
      .required('Required'),
    tippeeId: Yup.string()
      .required('Required'),
    provider: Yup.string()
      .required('Required'),
  })

  if (loadingProviders)
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <p>Loading...</p>
        </ClickAwayListener>
      </Dialog>
    )

  if (providerError)
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <p>Error...</p>
        </ClickAwayListener>
      </Dialog>
    )

  return (
    <Formik
      initialValues={{
        accessCode: accessCode,
        tippeeId: tippee.id,
        provider: tippee.payoutProcessor
      }}
      validationSchema={DoPayoutSchema}
      onSubmit={(values, { setSubmitting }) => {
        tippeeChangePayoutProvider({
          variables: {
            data: values,
          },
        })
          .then(() => {
            setSubmitting(false)
            onCloseForm()
            enqueueSnackbar('Changed payout provider successfully', { 
              variant: 'success',
            });
          })
          .catch((error) => {
            setSubmitting(false)
            enqueueSnackbar(error.message, { 
              variant: 'error',
            });
          })
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <div>
          <Form>
            <Dialog open={true} aria-labelledby="form-dialog-title">
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <DialogTitle id="form-dialog-title">Change Payout Provider</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Change how your tips are paid out to you
                    </DialogContentText>
                    <FormControl fullWidth color="primary">
                      <InputLabel htmlFor="provider">Provider</InputLabel>
                      <Field
                        component={Select}
                        name="provider"
                        type="string"
                        label="Payout Provider"
                        inputProps={{
                          id: 'provider',
                        }}
                        MenuProps={{ disablePortal: true }}
                        fullWidth
                      >
                        {providers.providers.map((processor) => {
                          return (
                            <MenuItem key={processor.key} value={processor.key}>
                              {`${processor.name} - ${processor.fee}`}
                            </MenuItem>
                          )
                        })}
                      </Field>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Choose
                    </Button>
                  </DialogActions>
                </div>
              </ClickAwayListener>
            </Dialog>
          </Form>
        </div>
      )}
    </Formik>
  )
}

DoPayoutForm.propTypes = {
  onCloseForm: PropTypes.func.isRequired,
  tippee: PropTypes.object.isRequired,
  accessCode: PropTypes.string.isRequired,
}

export default DoPayoutForm
