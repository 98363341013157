import { useMutation } from '@apollo/client'
import {
  ClickAwayListener,
} from '@material-ui/core'
import { TextField } from 'formik-material-ui';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'components/CustomButtons/Button.js'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import * as React from 'react'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack';
import { RESET_PASSWORD_BY_EMAIL } from 'gql/queries'

const ForgotPassword = function ForgotPassword({
  onCloseForm,
}) {
  const [resetPasswordByEmail] = useMutation(RESET_PASSWORD_BY_EMAIL)

  const { enqueueSnackbar } = useSnackbar();

  const handleClickAway = () => {
    onCloseForm()
  }

  const PasswordResetSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
  })

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={PasswordResetSchema}
      onSubmit={(values, { setSubmitting }) => {
        console.log("jellp?")
        resetPasswordByEmail({
          variables: {
            email: values.email,
          },
        })
          .then(() => {
            setSubmitting(false)
            onCloseForm()
            enqueueSnackbar('Password reset email sent successfully', { 
              variant: 'success',
            });
          })
          .catch((error) => {
            console.log(error)
            setSubmitting(false)
            enqueueSnackbar(error.message, { 
              variant: 'error',
            });
          })
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <div>
          <Form>
            <Dialog open={true} aria-labelledby="form-dialog-title">
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Enter your email address and a password reset link will be emailed to you
                    </DialogContentText>
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      label="Email"
                      color="secondary"
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Reset Password
                    </Button>
                  </DialogActions>
                </div>
              </ClickAwayListener>
            </Dialog>
          </Form>
        </div>
      )}
    </Formik>
  )
}

ForgotPassword.propTypes = {
  onCloseForm: PropTypes.func.isRequired,
}

export default ForgotPassword
