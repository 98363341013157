import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import StarRate from "@material-ui/icons/StarRate";
import StarRateOutlined from "@material-ui/icons/StarRateOutlined";
import { t4cYellowColor } from 'assets/jss/material-dashboard-react.js'

export default function Stars(props) {
  
  const useStyles = makeStyles((theme) => ({
      yellow: {
        color: t4cYellowColor
      },
      rating: {
        marginLeft: '5px',
        fontFamily: "'Betm', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      }
    }
  ));

  const classes = useStyles();

  const createStars = (rating) => {
    let stars = []

    // Outer loop to create parent
    for (let i = 0; i < Math.round(rating); i++) {
      stars.push(<StarRate key={i+1} className={classes.yellow}/>)
    }
    for (let i = 0; i < (5 - Math.round(rating)); i++) {
      stars.push(<StarRateOutlined key={5-i}/>)
    }
    return stars
  }

  const {
    rating,
  } = props;


  return (
    <React.Fragment>
      {createStars(rating)}
      <div className={classes.rating}>{rating.toFixed(1)}</div>
    </React.Fragment>
  );
}

Stars.propTypes = {
  rating: PropTypes.number
};
