// @material-ui/core components
import {
  TablePagination,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core'
// core components
import { makeStyles } from '@material-ui/core/styles'
import Table from 'components/Table/Table.js'
import PropTypes from 'prop-types'
import React from 'react'

const styles = theme => ({
  root: {
    overflow: 'visible',
  },
  searchBox: {
    '@media (max-width: 600px)': {
      minHeight: '48px',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterHolder: {
    '@media (max-width: 600px)': {
      padding: '0px 5px',
      display: 'block'
    },
    '@media (min-width: 600px)': {
      minHeight: '72px',
    },
    
  }
})

const useStyles = makeStyles(styles)

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function PaginatedTable(props) {
  const { tableData, initialRowsPerPage, filterFunc, locationFilter, locations, location } = props

  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage ? initialRowsPerPage : 50)
  const [searchQuery, setSearchQuery] = React.useState('')
  const timeoutRef = React.useRef()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filter = (data) => {
    if (filterFunc) {
      return filterFunc(data,searchQuery)
    } else {
      return data
    }
  };

  const search = (event) => {
    event.persist()
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setSearchQuery(event.target.value)
    }, 300)
  };

  const classes = useStyles()

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const displayData = filter(stableSort(tableData, getComparator(order, orderBy)))

  return (
    <React.Fragment>
      {(locationFilter || filterFunc) && <Grid container spacing={3} className={classes.filterHolder}>
        {filterFunc && <Grid item xs={12} sm={6}>
          <TextField fullWidth className={classes.searchBox} onChange={search} id="search" label="Search" />
        </Grid>}
        {locationFilter && <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel shrink id="location-filter-label">Location</InputLabel>
            <Select
              labelId="location-filter-label"
              id="location-filter"
              displayEmpty
              value={location}
              onChange={locationFilter}
              className={classes.selectEmpty}
            >
              {locations.map((value) => {
                return (
                  <MenuItem key={value.id} value={value.id}>
                    {value.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>}
      </Grid>}
      <Table
        {...props}
        tableData={displayData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
      />
      <TablePagination
        classes={{
          root: classes.root
        }}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={displayData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        SelectProps={{MenuProps : { disablePortal: true }}}
      />
    </React.Fragment>
  )
}

PaginatedTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
    ),
  ),
  locations: PropTypes.arrayOf(PropTypes.object),
  locationFilter: PropTypes.func,
  location: PropTypes.string,
  filterFunc: PropTypes.func
}
