
const tableHeadButton = {
  '@media (max-width: 600px)': {
    padding: '5px'
  },
}

const tableHeadButtonText = {
  '@media (max-width: 600px)': {
    display: 'none'
  },
  paddingRight: '5px'
}

const tableCard = {
  maxHeight: '100%',
  marginBottom: '0px',
  '@media (max-width: 600px)': {
    padding: '1px 0px 0px 0px',
  },
}

const tableCardBody = {
  overflowY: 'scroll',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '0px',
  '@media (max-width: 600px)': {
    padding: '0',
  },
}

const cardCategoryBlack = {
  '&,& a,& a:hover,& a:focus': {
    color: 'rgba(0,0,0,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  '& a,& a:hover,& a:focus': {
    color: '#000000',
  },
}

const cardTitleBlack = {
  color: '#000000',
  marginTop: '0px',
  minHeight: 'auto',
  fontWeight: '300',
  fontFamily: "'Betm', 'Roboto', 'Helvetica', 'Arial', sans-serif",
  marginBottom: '3px',
  textDecoration: 'none',
  '& small': {
    color: '#777',
    fontSize: '65%',
    fontWeight: '400',
    lineHeight: '1',
  },
  '@media (max-width: 600px)': {
    display: 'none',
  },
}

const coloredCellText = {
  textAlign: 'center',
  borderRadius: '3px',
  padding: '2px',
}

const headerButtonRight = {
  textAlign: 'right',
}

export {
  tableHeadButton,
  tableHeadButtonText,
  tableCard,
  tableCardBody,
  cardCategoryBlack,
  cardTitleBlack,
  headerButtonRight,
  coloredCellText
}
