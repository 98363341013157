import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from "classnames";
import PropTypes from "prop-types";
import Stars from 'components/Misc/Stars.js'
import Person from '@material-ui/icons/Person'
import { Avatar } from '@material-ui/core'

export default function TippeeHeader(props) {

  const useStyles = makeStyles((theme) => ({  
    tippeeImage: {
      height: '80px',
      width: '80px',
      borderRadius: '50%',
      border: '2px white solid',
      cursor: 'pointer',
    },
    tippeeName: {
      marginTop: '20px',
      fontSize: '32px',
      lineHeight: '20px',
      fontWeight: '300',
    },
    tippeeInfo: {
      marginTop: '16px',
      fontSize: '20px',
      lineHeight: '20px',
      fontWeight: '300',
    },
    starContainer: {
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    tippeeHeader: {
      textAlign: 'center',
      flexBasis: "40%",
      backgroundColor: theme.palette.secondary.main,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#FFFFFF',
      fontFamily: "'Betm', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    },
    profileCircle: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
      height: '80px',
      width: '80px',
      cursor: 'pointer',
    },
    profileIcon: {
      height: '65px',
      width: '65px',
    },
  }));
  
  const classes = useStyles();

  const {
    className,
    tippee,
    profileClickFunction,
    ...rest
  } = props;

  const tippeeHeader = classNames({
    [className]: className,
    [classes.tippeeHeader]: true,
  });
  
  return (
    <div {...rest} className={tippeeHeader}>
      {tippee.profileImageUrl ?
      (<img onClick={() => profileClickFunction ? profileClickFunction(tippee.id): null} className={classes.tippeeImage} src={tippee.profileImageUrl} alt='Tippee' />) :
      (
        <Avatar onClick={() => profileClickFunction ? profileClickFunction(tippee.id): null} className={classes.profileCircle}>
          <Person className={classes.profileIcon} />
        </Avatar>
      )}
      <div className={classes.tippeeName}>{tippee.firstName} {tippee.lastName}</div>
      <div className={classes.tippeeInfo}>{tippee.bio}</div>
      <div className={classes.starContainer}>
        <Stars rating={tippee.rating}/>
      </div>
    </div>
)}

TippeeHeader.propTypes = {
  className: PropTypes.string,
  tippee: PropTypes.object,
  profileClickFunction: PropTypes.func,
};

