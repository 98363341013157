/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from 'assets/jss/material-dashboard-react.js'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

// core components
import Admin from "layouts/Admin.js";
import Login from "views/Login/Login.js";
import Tippee from "views/Tippee/Tippee.js";
import TippeeTransactions from "views/Tippee/Transactions.js";
import ChangePassword from "views/Login/ChangePassword.js";

import "assets/fonts/Betm.otf"

import "assets/css/material-dashboard-react.css?v=1.9.0";
import "assets/css/custom.css";

import ApolloClient from 'apollo-boost';
import { typeDefs, resolvers } from "./gql/resolvers";
import { InMemoryCache } from "apollo-cache-inmemory";
import { PrivateRoute, getAuthToken, validToken } from 'services/auth'

const cache = new InMemoryCache();

const client = new ApolloClient({
  cache,
  uri: `${process.env.REACT_APP_T4C_API_ADDR}/graphql`,
  resolvers,
  typeDefs,
  request: (operation) => {
    const token = getAuthToken()
    if (validToken(token)) {
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : ''
        }
      })
    }
  }
});

const hist = createBrowserHistory();

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={hist}>
            <Switch>
              <PrivateRoute path="/admin" component={Admin} />
              <Route path="/login" component={Login} />
              <Route path="/tippee/:accessCode/transactions" component={TippeeTransactions} />
              <Route path="/tippee/:accessCode" component={Tippee} />
              <Route path="/change-password/:accessCode" component={ChangePassword} />
              <Redirect from="/" to="/admin/dashboard" />
            </Switch>
          </Router>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
