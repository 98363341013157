import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import classNames from "classnames";
import PropTypes from "prop-types";

export default function MobileOptimisedContainer(props) {

  const useStyles = makeStyles((theme) => ({
    mobileOptimisedContainer: {
      padding: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '@media (min-width: 600px)': {
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        border: '2px solid #E5E5E5',
      },
      color: '#FFFFFF',
      fontFamily: theme.typography.fontFamily,
      textAlign: 'center',
      position: 'relative'
    },
  }));
  
  const classes = useStyles();

  const {
    className,
    children,
    ...rest
  } = props;

  const mobileOptimisedContainer = classNames({
    [className]: className,
    [classes.mobileOptimisedContainer]: true,
  });
  
  return (
    <Container {...rest} maxWidth="sm" className={mobileOptimisedContainer}>
      {children}
    </Container>
)}

MobileOptimisedContainer.propTypes = {
  className: PropTypes.string
};

