import { useMutation } from '@apollo/client'
import {
  ClickAwayListener,
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
import Button from 'components/CustomButtons/Button.js'
import { Field, Form, Formik, useFormikContext } from 'formik'
import { SimpleFileUpload } from 'formik-material-ui'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import * as React from 'react'
import * as Yup from 'yup'
import Resizer from 'react-image-file-resizer';
import { useSnackbar } from 'notistack';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.black,
  },
  imageCropper: {
    margin: '0px 25px',
  }
}));

const SAVE_TIPPEE_IMAGE_KEY = gql`
  mutation saveProfileImageKey($data: FileInput!) {
    saveProfileImageKey(data: $data) {
      profileImageUrl
    }
  }
`

const GET_PROFILE_IMAGE_PUT_URL_QUERY = gql`
  mutation getProfileImagePutUrl($data: GetFilePutUrlInput!) {
    getProfileImagePutUrl(data: $data)
  }
`

const FILE_SIZE = 10 * 1024 * 1024
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']

const NewTippeeProfileImageSchema = Yup.object().shape({
  file: Yup.mixed()
    .required('A file is required')
    .test(
      'fileSize',
      'File too large. Maximum size 10MB',
      (value) => value && value.size <= FILE_SIZE,
    )
    .test(
      'fileFormat',
      'Unsupported Format. Please upload jpg, gif or png.',
      (value) => value && SUPPORTED_FORMATS.includes(value.type),
    ),
})

async function getCroppedImg(image, crop, file) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');
 
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      blob.name = file.name;
      resolve(blob);
    }, file.type, 1);
  });
}

const AddTippeeForm = function AddTippeeForm({ onCloseForm, tippeeId }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [saveTippeeImageKey] = useMutation(
    SAVE_TIPPEE_IMAGE_KEY,
  )
  const [getProfileImagePutUrl] = useMutation(
    GET_PROFILE_IMAGE_PUT_URL_QUERY,
  )

  const [uploadedImg, setUploadedImg] = React.useState(null)
  const [uploadedFile, setUploadedFile] = React.useState(null)
  const [crop, setCrop] = React.useState({ aspect: 1 / 1 });
  const [uploadingImage, setUploadingImage] = React.useState(null)

  const imgRef = React.useRef(null);

  const handleClickAway = () => {
    onCloseForm(false)
  }

  const onLoad = React.useCallback(img => {
    imgRef.current = img;
  }, []);

  function scaleAndUpload(inputFile) {
    Resizer.imageFileResizer(
      inputFile,
      200,
      200,
      'JPEG',
      100,
      0,
      blob => {
        getProfileImagePutUrl({
          variables: {
            data: {
              id: tippeeId,
              filename: uploadedFile.name,
              contentType: blob.type,
            },
          },
        })
          .then((result) => {
            const signedUrl = result.data.getProfileImagePutUrl
  
            const options = {
              headers: {
                'Content-Type': blob.type,
              },
            }
  
            return axios.put(signedUrl, blob, options)
          })
          .then(() => {
            return saveTippeeImageKey({
              variables: {
                data: {
                  id: tippeeId,
                  key: uploadedFile.name,
                },
              },
            })
          })
          .then(() => {
            enqueueSnackbar("Image saved successfully", {
              variant: 'success',
            });
            onCloseForm(true)
          })
          .catch((error) => {
            setUploadingImage(false)
            enqueueSnackbar(error.message, { 
              variant: 'error',
            });
          })
      },
      'blob'
    );
  }

  const AutoSubmitImage = () => {
    // Grab values and submitForm from context
    const { values, submitForm } = useFormikContext();
    React.useEffect(() => {
      submitForm();
    }, [values, submitForm]);
    return null;
  };
  
  async function submitCroppedImage() {
    if (crop.width < 200 || crop.height < 200) {
      enqueueSnackbar("Please choose a crop larger than 200x200", { 
        variant: 'error',
      });
    } else {
      setUploadingImage(true)
      const blob = await getCroppedImg(imgRef.current,crop,uploadedFile)
      scaleAndUpload(blob)
    }
  }

  if (uploadedImg) {
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <DialogTitle id="form-dialog-title">
              Crop Tippee Image
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleClickAway}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <ReactCrop className={classes.imageCropper} src={uploadedImg} crop={crop} minWidth={200} minHeight={200} onChange={newCrop => setCrop(newCrop)} onImageLoaded={onLoad} />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                disabled={uploadingImage}
                onClick={submitCroppedImage}
              >
                CROP IMAGE
              </Button>
            </DialogActions>
          </div>
        </ClickAwayListener>
      </Dialog>
    )
  }

  return (
    <Formik
      initialValues={{}}
      validationSchema={NewTippeeProfileImageSchema}
      onSubmit={(values) => {
        console.log(values.file)
        setUploadedFile(values.file)
        const reader = new FileReader()
        reader.addEventListener("load", () => setUploadedImg(reader.result))
        reader.readAsDataURL(values.file)
      }}
    >
      {() => (
        <div>
          <Form>
            <Dialog open={true} aria-labelledby="form-dialog-title">
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <DialogTitle id="form-dialog-title">
                    Add Tippee Image
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClickAway}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Add a profile image to this Tippee
                    </DialogContentText>
                    <Field
                      component={SimpleFileUpload}
                      name="file"
                      label="Add a new Tippee Profle Image below"
                    />
                    <AutoSubmitImage/>
                  </DialogContent>
                  <DialogActions>
                  </DialogActions>
                </div>
              </ClickAwayListener>
            </Dialog>
          </Form>
        </div>
      )}
    </Formik>
  )
}

AddTippeeForm.propTypes = {
  onCloseForm: PropTypes.func.isRequired,
  tippeeId: PropTypes.string.isRequired,
}

export default AddTippeeForm
