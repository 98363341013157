import { useMutation, useQuery } from '@apollo/client'
import {
  ClickAwayListener,
  IconButton,
  ListItemAvatar,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  List,
  ListItem,
  LinearProgress
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { Field, Form, Formik, useFormikContext } from 'formik'
import { SimpleFileUpload } from 'formik-material-ui'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import * as React from 'react'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack';
import { TIPPEE_FILES_QUERY, DELETE_TIPPEE_FILE } from 'gql/queries'
import { t4cBlueColor } from 'assets/jss/material-dashboard-react';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.black,
  },
  demo: {
    backgroundColor: "#DDD",
  },
  fileLink: {
    color: t4cBlueColor,
    textDecoration: 'underline',
    '&:hover, &:focus': {
      color: "#3c4858"
    }
  }
}));

const GET_PROFILE_IMAGE_PUT_URL_QUERY = gql`
  mutation getTippeeFilePutUrl($data: GetFilePutUrlInput!) {
    getTippeeFilePutUrl(data: $data)
  }
`

const FILE_SIZE = 10 * 1024 * 1024

const NewTippeeProfileFileSchema = Yup.object().shape({
  file: Yup.mixed()
    .required('A file is required')
    .test(
      'fileSize',
      'File too large. Maximum size 10MB',
      (value) => value && value.size <= FILE_SIZE,
    )
})


const TippeeFileManager = function TippeeFileManager({ onCloseForm, tippeeId }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [getTippeeFilePutUrl] = useMutation(
    GET_PROFILE_IMAGE_PUT_URL_QUERY,
  )

  const [deleteTippeeFile] = useMutation(
    DELETE_TIPPEE_FILE,
  )

  const { loading: loadingEntity, error: entityError, data: entityQuery, refetch } = useQuery(TIPPEE_FILES_QUERY,
    {
      variables: {
        id: tippeeId
      },
    })

  const [loading, setLoading] = React.useState(null)

  const handleClickAway = () => {
    onCloseForm(false)
  }

  const deleteFile = (key) => {
    setLoading(true)
    deleteTippeeFile({
      variables: {
        data: {
          id: tippeeId,
          key: key,
        },
      },
    })
    .then((result) => {
      enqueueSnackbar("File deleted successfully", {
        variant: 'success',
      });
      refetch()
    })
    .catch((error) => {
      enqueueSnackbar(error.message, { 
        variant: 'error',
      });
    })
    .finally( () => {
      setLoading(false)
    })
  }

  const AutoSubmitFile = () => {
    // Grab values and submitForm from context
    const { values, submitForm, isSubmitting } = useFormikContext();
    React.useEffect(() => {
      if (values.file && !isSubmitting) submitForm();
    }, [values.file, submitForm, isSubmitting]);
    return null;
  };

  if (loadingEntity)
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <div/>
        </ClickAwayListener>
      </Dialog>
    )

  if (entityError)
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <p>Error...</p>
        </ClickAwayListener>
      </Dialog>
    )

  const tippee = entityQuery.tippee

  return (
    <Formik
      initialValues={{}}
      validationSchema={NewTippeeProfileFileSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const file = values.file
        setLoading(true)
        getTippeeFilePutUrl({
          variables: {
            data: {
              id: tippeeId,
              filename: file.name,
              contentType: file.type,
            },
          },
        })
        .then((result) => {
          const signedUrl = result.data.getTippeeFilePutUrl
    
          const options = {
            headers: {
              'Content-Type': file.type,
            },
          }
    
          return axios.put(signedUrl, file, options)
        })
        .then( () => {
          resetForm()
          setSubmitting(false)
          setLoading(false)
          enqueueSnackbar("File uploaded successfully", {
            variant: 'success',
          });
          refetch()
        })
        .catch((error) => {
          setSubmitting(false)
          setLoading(false)
          enqueueSnackbar(error.message, { 
            variant: 'error',
          });
        })
      }}
    >
      {() => (
        <div>
          <Form>
            <Dialog open={true} aria-labelledby="form-dialog-title">
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <DialogTitle id="form-dialog-title">
                    File Manager
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClickAway}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <div className={classes.demo}>
                      <List>
                      {tippee.files.map((prop, key) => {
                        return (
                          <ListItem key={key}>
                            <ListItemAvatar>
                              <Avatar>
                                <FolderIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <a href={prop.url} target="_blank" className={classes.fileLink} rel="noopener noreferrer">
                              <ListItemText
                                primary={prop.filename}
                              />
                            </a>
                             
                            <ListItemSecondaryAction>
                              <IconButton edge="end" aria-label="delete" onClick={() => {deleteFile(prop.filename)}} disabled={loading}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      })}
                      {!tippee.files.length &&
                        <ListItem key={0}>
                          <ListItemText
                              primary="No files"
                            />
                        </ListItem>}
                      </List>
                    </div>
                    <Field
                      component={SimpleFileUpload}
                      name="file"
                      label="Add a new file"
                    />
                    <AutoSubmitFile/>
                    {loading && <LinearProgress />}
                  </DialogContent>
                  <DialogActions>
                  </DialogActions>
                </div>
              </ClickAwayListener>
            </Dialog>
          </Form>
        </div>
      )}
    </Formik>
  )
}

TippeeFileManager.propTypes = {
  onCloseForm: PropTypes.func.isRequired,
  tippeeId: PropTypes.string.isRequired,
}

export default TippeeFileManager
