import React from "react";
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { Route, Redirect } from "react-router-dom";

export const AUTH_COOKIE = 'tip4change.token'
let savedUser = null

export const getAuthToken = () => {
  const jwtToken = Cookies.get(AUTH_COOKIE)
  return jwtToken
}

export const setToken = (token) => {
  if (token) {
    sessionUser(token)
    Cookies.set(AUTH_COOKIE, token, { expires: 30 })
  }
}

export const clearToken = () => {
  Cookies.remove(AUTH_COOKIE)
  savedUser = null
}

export const isLoggedIn = (token) => {
  if (validToken(token)) {
    return true
  } else {
    clearToken()
    return false
  }
}

export const validToken = (token) => {
  if (token) {
    const currentTime = Date.now() / 1000
    const jwt = jwtDecode(token)
    if (jwt.exp < currentTime) {
      clearToken()
      return false
    }
    return true
  }
  return false
}

export const sessionUser = (token) => {
  if (savedUser) {
    return savedUser
  }
  if (token) {
    if (!validToken(token)) {
      clearToken()
      return null
    }
    const jwt = jwtDecode(token)
    savedUser = { id: jwt.id, role: jwt.role }
    return savedUser
  }
  return null
}

export function PrivateRoute ({component: Component, ...rest}) {
  const token = getAuthToken()
  const user = sessionUser(token)
  const authed = Boolean(validToken(token) && user)
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />
  )
}