import { useQuery } from '@apollo/client'
import { Avatar, CircularProgress } from '@material-ui/core'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import Person from '@material-ui/icons/Person'
import Create from '@material-ui/icons/Create'
import Visibility from '@material-ui/icons/Visibility'
import Comment from '@material-ui/icons/Comment'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import moment from 'moment'
import {
  t4cBlueColor,
  t4cYellowColor,
  t4cGreenColor,
  t4cOrangeColor
} from 'assets/jss/material-dashboard-react.js'
import {
  cardCategoryBlack,
  cardTitleBlack,
  headerButtonRight,
  tableHeadButton,
  tableHeadButtonText,
  tableCard,
  tableCardBody,
  coloredCellText,
} from 'assets/jss/tip4change-styles.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
// core components
import GridItem from 'components/Grid/GridItem.js'
import PaginatedTable from 'components/Table/PaginatedTable.js'
import React from 'react'

import AddTippeeForm from './AddTippeeForm'
import AddTippeeImage from './AddTippeeImage'
import ViewTippee from './ViewTippee'
import ViewComments from './ViewComments'
import DoPayoutForm from './DoPayoutForm'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import { TIPPEES_QUERY, LOCATIONS_LIST_QUERY } from 'gql/queries'
import TippeeFileManager from './TippeeFileManager'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

const styles = {
  profileImg: {
    height: '70px',
    borderRadius: '50%',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      height: '50px',
      width: '50px',
    },
  },
  profileCircle: {
    color: t4cYellowColor,
    backgroundColor: t4cBlueColor,
    height: '70px',
    width: '70px',
    cursor: 'pointer',
    '@media (max-width: 600px)': {
      height: '50px',
      width: '50px',
    },
  },
  profileIcon: {
    height: '55px',
    width: '55px',
    '@media (max-width: 600px)': {
      height: '35px',
      width: '35px',
    },
  },
  cardCategoryBlack,
  cardTitleBlack,
  headerButtonRight,
  tableHeadButton,
  tableHeadButtonText,
  tableCard,
  tableCardBody,
  coloredCellText
}

const useStyles = makeStyles(styles)

export default function TippeesManage() {
  const classes = useStyles()

  const [location, setLocation] = React.useState('')
  const [showAddTippeeForm, setShowAddTippeeForm] = React.useState(false)
  const [showViewTippeeDialog, setShowViewTippeeDialog] = React.useState(false)
  const [showViewCommentsDialog, setShowViewCommentsDialog] = React.useState(false)
  const [showAddImageForm, setShowAddImageForm] = React.useState(false)
  const [showDoPayoutForm, setShowDoPayoutForm] = React.useState(false)
  const [showFileManager, setShowFileManager] = React.useState(false)
  const [currentTippee, setCurrentTippee] = React.useState('')
  const [clickedRow, setClickedRow] = React.useState('')
  const [generatingPDF, setGeneratingPDF] = React.useState(false)
  const [selected, setSelected] = React.useState([])
  const anchorEl = React.useRef(null);

  const { loading, error, data, refetch } = useQuery(TIPPEES_QUERY,
    {
      variables: {
        locationId: location
      },
    })
  const { loading: loadingLocation, error: locationError, data: locationsData } = useQuery(LOCATIONS_LIST_QUERY)

  const openAddTippeeForm = (id) => {
    setCurrentTippee(id)
    setShowAddTippeeForm(true)
  }

  const openAddImageForm = (id) => {
    setCurrentTippee(id)
    setShowAddImageForm(true)
  }

  const toggleViewTippeeDialog = (id) => {
    if (id) setCurrentTippee(id)
    setShowViewTippeeDialog((showViewTippeeDialog) => {
      return !showViewTippeeDialog
    })
  }

  const toggleViewCommentsDialog = (id) => {
    if (id) setCurrentTippee(id)
    setShowViewCommentsDialog((showViewCommentsDialog) => {
      return !showViewCommentsDialog
    })
  }

  const toggleDoPayoutForm = (id) => {
    if (id) setCurrentTippee(id)
    setShowDoPayoutForm((showDoPayoutForm) => {
      return !showDoPayoutForm
    })
  }

  const toggleFileManager = (id) => {
    if (id) {
      setCurrentTippee(id)
    } else {
      toggleViewTippeeDialog()
    }
    setShowFileManager((showFileManager) => {
      return !showFileManager
    })
  }

  const tippeeAdded = (tippeeId) => {
    setShowAddTippeeForm(false)
    if (tippeeId) {
      refetch()
      setCurrentTippee(tippeeId)
      toggleViewTippeeDialog()
    }
  }

  const menuClick = (event) => {
    if (Boolean(anchorEl.current)) {
      anchorEl.current = null
      setClickedRow(null)
    } else {
      anchorEl.current = event.currentTarget
      setClickedRow(event.currentTarget.dataset.id)
    }
  }

  const menuItemClick = (id, func) => {
    anchorEl.current = null
    setClickedRow(null)
    func(id)
  }

  const imageAdded = (imageAdded) => {
    setShowAddImageForm(false)
    if (imageAdded) {
      refetch()
      toggleViewTippeeDialog()
    }
  }

  const filterFunc = (tableData, searchTerm) => {
    const lowerSearch = searchTerm.toLowerCase()
    return tableData.filter( (item) => {
      if (item[3].value.toLowerCase().includes(lowerSearch)) return true
      if (item[4].replace(/ /g,'').includes(lowerSearch)) return true
      if (item[8].data && item[8].data.toLowerCase().includes(lowerSearch)) return true
      return false
    })
  }

  const locationFilter = (event) => {
    setLocation(event.target.value)
    if (event.target.value === '') {
      refetch({
        locationId: event.target.value
      })
    }
  }

  const createBatchPDF = () => {
    setGeneratingPDF(true)
    const data = {
      ids: selected
    }
    fetch(`${process.env.REACT_APP_T4C_API_ADDR}/tippee/lanyard`, {
      method: "POST", 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then( res => res.blob() )
    .then( blob => {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `lanyard ${moment().format('YYYY-MM-DD hh:mm')}.pdf`);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
      setGeneratingPDF(false)
    });
  }

  function rowColor(type) {
    switch (type) {
      case true:
        return t4cGreenColor+'7D'
      case false:
        return t4cOrangeColor+'7D'
      default:
        return t4cGreenColor+'7D'
    }
  }

  if (loading || loadingLocation) return <p>Loading...</p>
  if (error || locationError) return <p>Error :(</p>

  const locations = [
    {
      id: '',
      name: "All"
    },
    ...locationsData.locations
  ]

  const tippees = data.tippees.map((tippee) => {
    return [
      {
        type: 'id',
        data: tippee.id,
      },
      {
        type: 'menu',
        anchorEl: anchorEl,
        handleClick: menuClick,
        id: tippee.id,
        func: menuItemClick,
        menuItems: [
          {
            name: 'View Tippee',
            func: toggleViewTippeeDialog,
            icon: <Visibility fontSize="small"/>
          },
          {
            name: 'Modify Tippee',
            func: openAddTippeeForm,
            icon: <Create fontSize="small"/>
          },
          {
            name: 'Payout',
            func: toggleDoPayoutForm,
            icon: <AccountBalanceWalletIcon fontSize="small"/>
          },
          {
            name: 'View Comments',
            func: toggleViewCommentsDialog,
            icon: <Comment fontSize="small"/>
          },
          {
            name: 'File Manager',
            func: toggleFileManager,
            icon: <InsertDriveFile fontSize="small"/>
          }
        ]
      },
      tippee.profileImageUrl ? (
        {
          type: 'img',
          handler: openAddImageForm,
          src: tippee.profileImageUrl,
          alt: 'Profile',
          class: classes.profileImg,
          id: tippee.id,
        }
      ) : (
        <Avatar onClick={() => openAddImageForm(tippee.id)} className={classes.profileCircle}>
          <Person className={classes.profileIcon} />
        </Avatar>
      ),
      {
        type: 'txType',
        value: `${tippee.firstName} ${tippee.lastName}`,
        color: rowColor(tippee.eftPayable),
        class: classes.coloredCellText
      },
      `${tippee.mobileNumber.slice(0,3)} ${tippee.mobileNumber.slice(3,5)} ${tippee.mobileNumber.slice(5,8)} ${tippee.mobileNumber.slice(8,12)}`,
      `R${(tippee.balance/100).toFixed(2)}`,
      {
        type: 'text',
        text: tippee.location ? tippee.location.name : 'unassigned',
        hideCellOnMobile: false
      },
      {
        type: 'text',
        text: tippee.idNumber,
        hideCellOnMobile: true
      },
      {
        type: 'hidden',
        data: tippee.nickname,
      }
      
    ]
  })

  return (
    <React.Fragment>
      {showAddTippeeForm && <AddTippeeForm onCloseForm={tippeeAdded} entityId={currentTippee} />}
      {showAddImageForm && currentTippee && (
        <AddTippeeImage tippeeId={currentTippee} onCloseForm={imageAdded} />
      )}
      {showViewTippeeDialog && <ViewTippee tippeeId={currentTippee} onCloseForm={toggleViewTippeeDialog} profileClickFunction={openAddImageForm} toggleFileManager={toggleFileManager}/>}
      {showViewCommentsDialog && <ViewComments tippeeId={currentTippee} onCloseForm={toggleViewCommentsDialog}/>}
      {showDoPayoutForm && <DoPayoutForm tippeeId={currentTippee} onCloseForm={toggleDoPayoutForm}/>}
      {showFileManager && <TippeeFileManager tippeeId={currentTippee} onCloseForm={toggleFileManager}/>}
      <Card className={classes.tableCard}>
        <CardHeader color="primary">
          <GridContainer>
            <GridItem xs={6} sm={4} md={4}>
              <h4 className={classes.cardTitleBlack}>Tippees</h4>
              <p className={classes.cardCategoryBlack}>
                All of the tippees in your organisation
              </p>
            </GridItem>
            <GridItem
              xs={6}
              sm={8}
              md={8}
              classes={{ root: classes.headerButtonRight }}
            >
              <Button disabled={!selected.length || generatingPDF} className={classes.tableHeadButton} color="primary" onClick={() => createBatchPDF()}>
                <div className={classes.tableHeadButtonText}>Download PDF</div>
                {generatingPDF ? <CircularProgress color="black" size='18px'/> : <PictureAsPdfIcon />}
              </Button>
              <Button className={classes.tableHeadButton} color="primary" onClick={() => openAddTippeeForm('')}>
                <div className={classes.tableHeadButtonText}>Add Tippee</div>
                <Add />
              </Button>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody className={classes.tableCardBody}>
          <PaginatedTable
            tableHeaderColor="gray"
            tableHead={[
              {
                text: '',
                hideCellOnMobile: false
              },
              {
                text: 'Image',
                hideCellOnMobile: false
              },
              {
                text: 'Name',
                hideCellOnMobile: false
              },
              {
                text: 'Mobile',
                hideCellOnMobile: false
              },
              {
                text: 'Balance',
                hideCellOnMobile: false
              },
              {
                text: 'Location',
                hideCellOnMobile: false
              },
              {
                text: 'ID',
                hideCellOnMobile: true
              },
            ]}
            tableData={tippees}
            filterFunc={filterFunc}
            clickedRow={clickedRow}
            locations={locations}
            locationFilter={locationFilter}
            location={location}
            selected={selected}
            setSelected={setSelected}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
