/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import OrganisationsManage from "views/OrganisationsManage/OrganisationsManage.js";
import TippeesManage from "views/TippeesManage/TippeesManage.js";
import LocationsManage from "views/LocationsManage/LocationsManage.js";
import UsersManage from "views/UsersManage/UsersManage.js";

let dashboardRoutes = []

dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    display: ['ADMIN','ORG_MANAGER','LOCATION_MANAGER','USER'],
  },
  {
    path: "/tippees",
    name: "Tippees",
    icon: Person,
    component: TippeesManage,
    layout: "/admin",
    display: ['ADMIN','ORG_MANAGER','LOCATION_MANAGER','USER'],
  },
  {
    path: "/locations",
    name: "Locations",
    icon: "home_work",
    component: LocationsManage,
    layout: "/admin",
    display: ['ORG_MANAGER','ADMIN']
  },
  {
    path: "/organisations",
    name: "Organisations",
    icon: "corporate_fare",
    component: OrganisationsManage,
    layout: "/admin",
    display: ['ADMIN'],
  },
  {
    path: "/users",
    name: "Users",
    icon: "admin_panel_settings",
    component: UsersManage,
    layout: "/admin",
    display: ['ADMIN']
  },
];

export default dashboardRoutes;
