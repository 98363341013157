import { useMutation, useQuery } from '@apollo/client'
import {
  ClickAwayListener,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'components/CustomButtons/Button.js'
import { Field, Form, Formik } from 'formik'
import { Select, TextField } from 'formik-material-ui'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import * as React from 'react'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack';
import { PROVIDER_QUERY } from 'gql/queries'

const DO_PAYOUT = gql`
  mutation doPayout($data: CreatePayoutInput!) {
    doPayout(data: $data) {
      id
      processorTxId
    }
  }
`

const TIPPEE_QUERY = gql`
  query tippee($id: String!) {
    tippee(id: $id) {
      balance
    }
  }
`

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.black,
  },
}));

const DoPayoutForm = function DoPayoutForm({ onCloseForm, tippeeId }) {
  const [doPayout] = useMutation(DO_PAYOUT)
  const { loading: loadingTippees, error: tippeeError, data: tippees, refetch } = useQuery(TIPPEE_QUERY,
    {
      variables: { id: tippeeId },
    })

  const { loading: loadingProviders, error: providerError, data: providers } = useQuery(PROVIDER_QUERY,
    {
      variables: { id: tippeeId },
    })

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleClickAway = () => {
    onCloseForm()
  }

  const DoPayoutSchema = Yup.object().shape({
    totalInCents: Yup.number()
      .min(20, 'Too Short!')
      .max(tippees?.tippee.balance, 'Too Long!')
      .required('Required'),
    provider: Yup.string()
      .required('Required'),
  })

  if (loadingTippees || loadingProviders)
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <div/>
        </ClickAwayListener>
      </Dialog>
    )

  if (tippeeError || providerError)
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <p>Error...</p>
        </ClickAwayListener>
      </Dialog>
    )

  return (
    <Formik
      initialValues={{
        totalInCents: tippees.tippee.balance,
        provider: ''
      }}
      validationSchema={DoPayoutSchema}
      onSubmit={(values, { setSubmitting }) => {
        doPayout({
          variables: {
            data: {
              ...values,
              tippeeId
            },
          },
        })
          .then(() => {
            refetch()
            onCloseForm()
          })
          .catch((error) => {
            enqueueSnackbar(error.message, { 
              variant: 'error',
            });
          })
          .finally(() => {
            setSubmitting(false)
          })
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <div>
          <Form>
            <Dialog open={true} aria-labelledby="form-dialog-title">
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <DialogTitle id="form-dialog-title">
                    Do Payout
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClickAway}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Perform a payout for this tippee
                    </DialogContentText>
                    <Field
                      component={TextField}
                      name="totalInCents"
                      type="number"
                      label="Payout in cents"
                      fullWidth
                    />
                    <FormControl fullWidth color="primary">
                      <InputLabel htmlFor="provider">Provider</InputLabel>
                      <Field
                        component={Select}
                        name="provider"
                        type="string"
                        label="Payout Provider"
                        inputProps={{
                          id: 'provider',
                        }}
                        MenuProps={{ disablePortal: true }}
                        fullWidth
                      >
                        {providers.providers.map((processor) => {
                          return (
                            <MenuItem key={processor.key} value={processor.key}>
                              {`${processor.name} - ${processor.fee}`}
                            </MenuItem>
                          )
                        })}
                      </Field>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Payout
                    </Button>
                  </DialogActions>
                </div>
              </ClickAwayListener>
            </Dialog>
          </Form>
        </div>
      )}
    </Formik>
  )
}

DoPayoutForm.propTypes = {
  onCloseForm: PropTypes.func.isRequired,
  tippeeId: PropTypes.string.isRequired,
}

export default DoPayoutForm
