import { useQuery } from '@apollo/client'
import {
  ClickAwayListener,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import {
  t4cBlueColor,
} from 'assets/jss/material-dashboard-react.js'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import * as React from 'react'
import PaginatedTable from 'components/Table/PaginatedTable.js'
import moment from 'moment'
import { TIPPEE_COMMENTS_QUERY } from 'gql/queries'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "'Betm', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: '#000000',
  },
  dialogTitle: {
    backgroundColor: t4cBlueColor,
  },
}));

const ViewComments = function ViewComments({ onCloseForm, tippeeId }) {

  const classes = useStyles();

  const { loading, error, data } = useQuery(TIPPEE_COMMENTS_QUERY,
    {
      variables: { id: tippeeId },
    })


  const handleClickAway = () => {
    onCloseForm()
  }

  if (loading)
    return (
      <Dialog open={true} aria-labelledby="tippee-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <div/>
        </ClickAwayListener>
      </Dialog>
    )

  if (error)
    return (
      <Dialog open={true} aria-labelledby="tippee-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <p>Error...</p>
        </ClickAwayListener>
      </Dialog>
    )

  function compare(a, b) {
    return a.timestamp > b.timestamp ? -1 : 1;
  }

  data.tippee.comments.sort(compare);

  const comments = data.tippee.comments.map((trans) => {
    return [
      trans.comment,
      'R'+(trans.payment.totalInCents/100).toFixed(2),
      moment(trans.createDateTime).format('LLL'),
    ]
  })

  const tippeeName = data.tippee.firstName

  return (
    <Dialog open={true} aria-labelledby="tippee-dialog-title" className={classes.root} maxWidth='md'>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <DialogTitle id="tippee-dialog-title" className={classes.dialogTitle}>
            {tippeeName}'s Comments
          </DialogTitle>
          <DialogContent className={classes.contents}>
            <PaginatedTable
              tableHead={[
                'Comment',
                'Amount',
                'Time',
              ]}
              tableData={comments}
            />
          </DialogContent>
        </div>
      </ClickAwayListener>
    </Dialog>
  )
}

ViewComments.propTypes = {
  onCloseForm: PropTypes.func.isRequired,
  tippeeId: PropTypes.string.isRequired,
}

export default ViewComments
