import { useMutation, useQuery } from '@apollo/client'
import {
  ClickAwayListener,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton,
  FormHelperText
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'components/CustomButtons/Button.js'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, Select, TextField } from 'formik-material-ui'
import { DatePicker } from 'formik-material-ui-pickers';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import FormikPhoneInput from 'components/Misc/FormikPhoneInput'
import PropTypes from 'prop-types'
import * as React from 'react'
import * as Yup from 'yup'
import { LOCATIONS_LIST_QUERY, CREATE_OR_MODIFY_TIPPEE, TIPPEE_QUERY } from 'gql/queries'
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.black,
  },
}));

const bankAccountTypes = ['Current', 'Savings', 'Transmission', 'Bond', 'Subscription', 'Share']

const NewTippeeSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  nickname: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!'),
  bio: Yup.string()
    .min(2, 'Too Short!')
    .max(300, 'Too Long!'),
  idNumber: Yup.string()
    .min(2, 'Too Short!')
    .max(300, 'Too Long!')
    .required('Required'),
  address: Yup.string()
    .min(2, 'Too Short!')
    .max(300, 'Too Long!'),
  dob: Yup.date()
    .nullable()
    .notRequired(),
  email: Yup.string().email('Invalid email'),
  mobileNumber: Yup.mixed().test({
    message: 'Invalid mobile',
    test: (value) => {
      if (!value) return false
      const phoneNumber = parsePhoneNumberFromString(value)
      if (!phoneNumber) return false
      return phoneNumber.isValid()
    },
  }).required('Required'),
  locationId: Yup.string().required('Required'),
  bankBranchCode: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!'),
  bankAccountNumber: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!'),
  bankAccountHoldersName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!'),
  eftAccountType: Yup.mixed()
    .oneOf(bankAccountTypes)
})

const AddTippeeForm = function AddTippeeForm({
  onCloseForm,
  entityId,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  
  const [createOrModifyTippee] = useMutation(CREATE_OR_MODIFY_TIPPEE)
  const { loading: loadingLocation, error: locationError, data } = useQuery(LOCATIONS_LIST_QUERY)
  const { loading: loadingEntity, error: entityError, data: entityQuery } = useQuery(TIPPEE_QUERY,
    {
      variables: {
        id: entityId
      },
    })

  const handleClickAway = () => {
    onCloseForm(null)
  }

  if (loadingLocation || loadingEntity)
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <div/>
        </ClickAwayListener>
      </Dialog>
    )

  if (locationError || entityError)
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <p>Error...</p>
        </ClickAwayListener>
      </Dialog>
    )

  const tippee = entityQuery?.tippee

  return (
    <Formik
      initialValues={{
        id: tippee ? entityId : '',
        firstName: tippee && tippee.firstName ? tippee.firstName : '',
        lastName: tippee && tippee.lastName ? tippee.lastName : '',
        nickname: tippee && tippee.nickname ? tippee.nickname : '',
        bio: tippee && tippee.bio ? tippee.bio : '',
        idNumber: tippee && tippee.idNumber ? tippee.idNumber : '',
        address: tippee && tippee.address ? tippee.address : '',
        dob: tippee && tippee.dob ? tippee.dob : new Date(),
        email: tippee && tippee.email ? tippee.email : '',
        mobileNumber: tippee ? tippee.mobileNumber : '',
        locationId: tippee && tippee.location ? tippee.location.id : '',
        bankBranchCode: tippee && tippee.bankBranchCode ? tippee.bankBranchCode : '',
        bankAccountNumber: tippee && tippee.bankAccountNumber ? tippee.bankAccountNumber : '',
        bankAccountHoldersName: tippee && tippee.bankAccountHoldersName ? tippee.bankAccountHoldersName : '',
        eftAccountType: tippee && tippee.eftAccountType ? tippee.eftAccountType : 'Current',
        sendSms: true
      }}
      validationSchema={NewTippeeSchema}
      onSubmit={(values, { setSubmitting }) => {
        createOrModifyTippee({
          variables: {
            data: values,
          },
        })
          .then((response) => {
            setSubmitting(false)
            onCloseForm(response.data.createOrModifyTippee.id)
          })
          .catch((error) => {
            setSubmitting(false)
            enqueueSnackbar(error.message, { 
              variant: 'error',
            });
          })
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Dialog open={true} aria-labelledby="form-dialog-title">
            <ClickAwayListener onClickAway={handleClickAway}>
              <div>
                <DialogTitle id="form-dialog-title">
                  {tippee ? 'Modify' : 'Add'} Tippee
                  <IconButton aria-label="close" className={classes.closeButton} onClick={handleClickAway}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {tippee ? 'Modify a Tippee on' : 'Add a Tippee to'} your Location
                  </DialogContentText>
                  <Field
                    component={TextField}
                    name="firstName"
                    type="string"
                    label="First Name (Required)"
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="lastName"
                    type="string"
                    label="Last Name (Required)"
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="nickname"
                    type="string"
                    label="Nickname (Optional)"
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="bio"
                    type="string"
                    label="Biography (Optional)"
                    fullWidth
                    multiline
                  />
                  <Field
                    component={TextField}
                    name="idNumber"
                    type="string"
                    label="ID Number (Required)"
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="address"
                    type="string"
                    label="Address (Optional)"
                    fullWidth
                    multiline
                  />
                  <Field
                    component={DatePicker}
                    label="Date of Birth (Required)"
                    name="dob"
                    disableFuture
                    openTo="year"
                    format="DD/MM/yyyy"
                    views={["year", "month", "date"]}
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label="Email (Optional)"
                    fullWidth
                  />
                  <Field
                    component={FormikPhoneInput}
                    name="mobileNumber"
                    type="string"
                    inputExtraProps={{
                      name: 'mobileNumber',
                      label: "Mobile Number (Required)"
                    }}
                  />
                  <ErrorMessage component={FormHelperText} error={true} name="mobileNumber" />
                  {!tippee &&
                  (
                    <Field
                    component={CheckboxWithLabel}
                    name="sendSms"
                    Label={{
                      label: 'Send SMS on creation?',
                    }}
                    type="checkbox"
                  />
                  )}
                  
                  <FormControl fullWidth>
                    <InputLabel htmlFor="location-id">Location</InputLabel>
                    <Field
                      component={Select}
                      name="locationId"
                      type="string"
                      label="Location (Required)"
                      inputProps={{
                        id: 'location-id',
                      }}
                      MenuProps={{ disablePortal: true }}
                      fullWidth
                    >
                      {data.locations.map((value) => {
                        return (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        )
                      })}
                    </Field>
                  </FormControl>
                  <Field
                    component={TextField}
                    name="bankBranchCode"
                    type="string"
                    label="Bank Branch Code (Optional)"
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="bankAccountNumber"
                    type="string"
                    label="Bank Account Number (Optional)"
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="bankAccountHoldersName"
                    type="string"
                    label="Bank Account Holder's Name (Optional)"
                    fullWidth
                  />
                  <FormControl fullWidth>
                    <InputLabel htmlFor="eftAccountType-id">EFT Account Type</InputLabel>
                    <Field
                      component={Select}
                      name="eftAccountType"
                      type="string"
                      label="EFT Account Type (Optional)"
                      inputProps={{
                        id: 'eftAccountType-id',
                      }}
                      MenuProps={{ disablePortal: true }}
                      fullWidth
                    >
                      {bankAccountTypes.map((value) => {
                        return (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        )
                      })}
                    </Field>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    onClick={submitForm}
                  >
                    {tippee ? 'MODIFY' : 'ADD'} TIPPEE
                  </Button>
                </DialogActions>
              </div>
            </ClickAwayListener>
          </Dialog>
        </Form>
      )}
    </Formik>
  )
}

AddTippeeForm.propTypes = {
  onCloseForm: PropTypes.func.isRequired,
  entityId: PropTypes.string,
}

export default AddTippeeForm
