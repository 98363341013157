import gql from "graphql-tag"
// import * as ls from 'local-storage'
// import { customerDetailsQuery } from './queries'

export const typeDefs = gql`

  type UserSignupInput {
    email: String!
    password: String!
  }

  type ChangePasswordFromLinkInput {
    accessCode: String!
    password: String! 
  }

  extend type Mutation {
    userSignInMutation(email: String!, password: String!): token!
    userSignUpMutation(input: UserSignupInput!): token!
  }
`;

export const resolvers = {
  
};