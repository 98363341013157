import gql from "graphql-tag"

export const userSignInMutation = gql`
  mutation userSignIn($email: String!, $password: String!) {
    userSignIn(email: $email, password: $password) {
      token
    }
  }
`

export const userSignUpMutation = gql`
  mutation userSignUp($data: UserSignupInput!) {
    userSignUp(data: $data) {
      token
    }
  }
`

export const userPasswordChangeMutation = gql`
  mutation userPasswordChange($data: ChangePasswordFromLinkInput!) {
    userPasswordChange(data: $data) {
      token
    }
  }
`

export const CREATE_OR_MODIFY_TIPPEE = gql`
  mutation createOrModifyTippee($data: CreateTippeeInput!) {
    createOrModifyTippee(data: $data) {
      id
      firstName
      lastName
      nickname
      dob
      address
      idNumber
      email
      mobileNumber
      locked
      profileImageUrl
      bio
      bankBranchCode
      bankAccountNumber
      bankAccountHoldersName
      eftAccountType
      location {
        id
        name
      }
    }
  }
`

export const TIPPEES_QUERY = gql`
  query tippees($locationId: String) {
    tippees(locationId: $locationId) {
      id
      firstName
      lastName
      nickname
      idNumber
      email
      mobileNumber
      profileImageUrl
      balance
      eftPayable
      location {
        id
        name
      }
    }
  }
`

export const TIPPEE_QUERY = gql`
  query tippee($id: String!) {
    tippee(id: $id) {
      id
      firstName
      lastName
      nickname
      dob
      address
      idNumber
      email
      mobileNumber
      locked
      profileImageUrl
      bankBranchCode
      bankAccountNumber
      bankAccountHoldersName
      eftAccountType
      location {
        id
        name
      }
      qrCode
      bio
      balance
      rating
      transactions {
        id
        totalInCents
        timestamp
        type
        provider
        description
      }
      tippeeAccessLink
    }
  }
`

export const TIPPEE_FILES_QUERY = gql`
  query tippee($id: String!) {
    tippee(id: $id) {
      id
      files {
        filename
        url
      }
    }
  }
`

export const TIPPEE_COMMENTS_QUERY = gql`
  query tippee($id: String!) {
    tippee(id: $id) {
      id
      firstName
      comments {
        id
        comment
        payment {
          totalInCents
        }
        createDateTime
      }
    }
  }
`

export const DELETE_TIPPEE_FILE = gql`
mutation deleteTippeeFile($data: FileInput!) {
  deleteTippeeFile(data: $data)
}
`

export const LATEST_TIPPEES = gql`
{
  latestTippees {
    id
    firstName
    lastName
    createDateTime
    location {
      id
      name
    }
  }
}
`

export const tippeeWithAccessCodeQuery = gql`
  query tippeeWithAccessCode($accessCode: String!) {
    tippeeWithAccessCode(accessCode: $accessCode) {
      id
      firstName
      lastName
      idNumber
      email
      mobileNumber
      profileImageUrl
      qrCode
      location {
        id
        name
      }
      bio
      transactions {
        id
        totalInCents
        timestamp
        type
        provider
        description
      }
      balance
      rating
      payoutProcessor
      payoutProcessorName
    }
  }
`

export const TIPPEE_DO_PAYOUT_MUTATION = gql`
  mutation tippeeDoPayout($data: TippeeCreatePayoutInput!) {
    tippeeDoPayout(data: $data) {
      id
      totalInCents
      createDateTime
      payoutProcessor
    }
  }
`;

export const TIPPEE_CHANGE_PAYOUT_PROVIDER_MUTATION = gql`
  mutation tippeeChangePayoutProvider($data: TippeeChangePayoutProviderInput!) {
    tippeeChangePayoutProvider(data: $data) {
      id
      payoutProcessor
      payoutProcessorName
    }
  }
`;

export const SEND_TIPPEE_SMS = gql`
mutation sendTippeeSMS($id: String!) {
  sendTippeeSMS(id: $id)
}
`;

export const USER_WITH_ACCESS_CODE_QUERY = gql`
  query userWithAccessCode($accessCode: String!) {
    userWithAccessCode(accessCode: $accessCode) {
      id
      email
    }
  }
`

export const CREATE_OR_MODIFY_USER = gql`
  mutation createOrModifyUser($data: CreateUserInput!) {
    createOrModifyUser(data: $data) {
      id
      name
    }
  }
`;

export const RESET_PASSWORD_BY_ID = gql`
mutation resetPasswordById($id: String!) {
  resetPasswordById(id: $id)
}
`;

export const RESET_PASSWORD_BY_EMAIL = gql`
mutation resetPasswordByEmail($email: String!) {
  resetPasswordByEmail(email: $email)
}
`;

export const ROLE_QUERY = gql`
  query roles {
    roles
  }
`

export const LOCATIONS_QUERY = gql`
  {
    locations {
      id
      name
      address
      province
      country
      contactName
      contactEmail
      mobileNumber
      tippeeCount
      organisation {
        id
        name
      }
    }
  }
`

export const LOCATIONS_LIST_QUERY = gql`
  {
    locations {
      id
      name
    }
  }
`

export const LOCATION_QUERY = gql`
query location($id: String!) {
  location(id: $id) {
    id
    name
    address
    province
    country
    contactName
    contactEmail
    mobileNumber
    organisation {
      id
    }
  }
}
`

export const CREATE_OR_MODIFY_LOCATION = gql`
mutation createOrModifyLocation($data: CreateLocationInput!) {
  createOrModifyLocation(data: $data) {
    id
    name
  }
}
`

export const ORGS_LIST_QUERY = gql`
  {
    organisations {
      id
      name
    }
  }
`

export const USERS_QUERY = gql`
  {
    users {
      id
      name
      email
      mobile
      organisation {
        id
        name
      }
      locations {
        id
        name
      }
    }
  }
`

export const USER_QUERY = gql`
query user($id: String!) {
  user(id: $id) {
    name
    email
    mobile
    role
    organisation {
      id
    }
    locations {
      id
    }
  }
}
`

export const ORG_QUERY = gql`
query organisation($id: String!) {
  organisation(id: $id) {
    id
    name
    address
    province
    country
    contactName
    contactEmail
    mobileNumber
  }
}
`

export const ORGS_QUERY = gql`
  {
    organisations {
      id
      name
      address
      province
      country
      contactName
      contactEmail
      mobileNumber
      locationCount
      tippeeCount
      locations {
        name
      }
    }
  }
`

export const CREATE_OR_MODIFY_ORG = gql`
mutation createOrModifyOrganisation($data: CreateOrganisationInput!) {
  createOrModifyOrganisation(data: $data) {
    id
    name
  }
}
`

export const PROVIDER_QUERY = gql`
query providers {
  providers {
    key
    name
    fee
  }
}
`

export const TOTAL_FEES = gql`
query totalFees($tz: Float!, $startDate: DateTime!) {
  totalFees(tz: $tz, startDate: $startDate)
}
`

export const TOTAL_PAYMENTS = gql`
query totalPayments($tz: Float!, $startDate: DateTime!) {
  totalPayments(tz: $tz, startDate: $startDate)
}
`
