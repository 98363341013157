import { useQuery, useMutation } from '@apollo/client'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  Tooltip,
  IconButton
} from '@material-ui/core'
import {
  cardCategoryBlack,
  cardTitleBlack,
  headerButtonRight,
  tableHeadButton,
  tableHeadButtonText,
  tableCard,
  tableCardBody,
} from 'assets/jss/tip4change-styles.js'
import CloseIcon from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CustomButton from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Edit from '@material-ui/icons/Edit'
import Lock from '@material-ui/icons/Lock'
// core components
import GridItem from 'components/Grid/GridItem.js'
import PaginatedTable from 'components/Table/PaginatedTable.js'
import React from 'react'
import { useSnackbar } from 'notistack';

import AddUserForm from './AddUserForm'
import { USERS_QUERY, RESET_PASSWORD_BY_ID } from 'gql/queries'

const useStyles = makeStyles((theme) => ({
  cardCategoryBlack,
  cardTitleBlack,
  headerButtonRight,
  tableHeadButton,
  tableHeadButtonText,
  tableCard,
  tableCardBody,
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.black,
  },
}));

export default function UsersManage() {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar();
  const { loading, error, data, refetch } = useQuery(USERS_QUERY)
  const [resetPasswordById] = useMutation(RESET_PASSWORD_BY_ID)
  const [showForm, setShowForm] = React.useState(false)
  const [showPasswordReset, setShowPasswordReset] = React.useState(false)
  const [entityId, setEntityId] = React.useState('')
  const [resettingPassword, setResettingPassword] = React.useState(false)

  const openForm = (id) => {
    setEntityId(id)
    setShowForm(true)
  }

  const userAdded = (newData) => {
    setShowForm(false)
    if (newData) refetch()
  }

  const openResetPassword = (id) => {
    setEntityId(id)
    setShowPasswordReset(true)
  }

  const resetPassword = () => {
    setResettingPassword(true)
    resetPasswordById({
      variables: {
        id: entityId
      },
    })
    .then(() => {
      refetch()
      setShowPasswordReset(false)
      enqueueSnackbar('Password Reset Sent', { 
        variant: 'success',
      });
    })
    .catch((error) => {
      enqueueSnackbar(error.message, { 
        variant: 'error',
      });
    })
    .finally(() => {
      setResettingPassword(false)
    })
  }

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  const users = data.users.map((user) => {
    return [
      user.name,
      user.email,
      user.mobile || 'none',
      user.organisation ? user.organisation.name : 'none',
      user.locations.length ? user.locations.map(e => e.name).join(", ") : 'none',
      {
        type: 'icon-button',
        handler: openForm,
        id: user.id,
        child: (
          <Tooltip title="Edit User">
            <Edit />
          </Tooltip>
        ),
      },
      {
        type: 'icon-button',
        handler: openResetPassword,
        id: user.id,
        child: (
          <Tooltip title="Reset Password">
            <Lock />
          </Tooltip>
        ),
      },
    ]
  })

  return (
    <React.Fragment>
      {showForm && <AddUserForm onCloseForm={userAdded} entityId={entityId} />}
      <Card className={classes.tableCard}>
        <CardHeader color="primary">
          <GridContainer>
            <GridItem xs={8} sm={8} md={8}>
              <h4 className={classes.cardTitleBlack}>Users</h4>
              <p className={classes.cardCategoryBlack}>
                All of the users within Tip4Change
              </p>
            </GridItem>
            <GridItem
              xs={4}
              sm={4}
              md={4}
              classes={{ root: classes.headerButtonRight }}
            >
              <CustomButton className={classes.tableHeadButton}color="primary" onClick={() => openForm('')}>
                <div className={classes.tableHeadButtonText}>Add User </div>
                <Add />
              </CustomButton>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody className={classes.tableCardBody}>
          <PaginatedTable
            tableHeaderColor="gray"
            tableHead={['Name', 'Email', 'Mobile', 'Organisation', 'Location', '', '']}
            tableData={users}
          />
        </CardBody>
      </Card>
      <Dialog
        open={showPasswordReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ClickAwayListener onClickAway={() => setShowPasswordReset(false)}>
          <div>
            <DialogTitle id="alert-dialog-title">
              {"Send password reset?"}
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => setShowPasswordReset(false)}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Would you like to send a password reset email to this user's email address?
              </DialogContentText>
            </DialogContent>
            {resettingPassword && <LinearProgress />}
            <DialogActions>
              <Button onClick={() => setShowPasswordReset(false)} color="secondary" disabled={resettingPassword}>
                Cancel
              </Button>
              <Button onClick={resetPassword} color="secondary" autoFocus disabled={resettingPassword}>
                {resettingPassword ? "Sending Reset Email..." : "Send Reset Email"}
              </Button>
            </DialogActions>
          </div>
        </ClickAwayListener>
      </Dialog>
    </React.Fragment>
  )
}
