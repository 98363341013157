import React from "react";
import { tippeeWithAccessCodeQuery } from 'gql/queries'
import { makeStyles } from '@material-ui/core/styles';
import MobileOptimisedContainer from 'components/Layout/MobileOptimisedContainer'
import PaginatedTable from 'components/Table/PaginatedTable.js'
import moment from 'moment'
import {
  t4cBlueColor,
  t4cGreenColor,
  t4cOrangeColor,
  t4cPurpleColor,
  t4cRedColor
} from 'assets/jss/material-dashboard-react.js'
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client'

const useStyles = makeStyles({
  feeIcon: {
    textAlign: 'center',
    borderRadius: '3px',
    padding: '2px',
  },
  headClass: {
    backgroundColor: t4cBlueColor,
    color: 'black',
  },
  paginationClass: {
    backgroundColor: t4cBlueColor,
    overflow: 'unset'
  }
});

export default function TippeeTransactions() {

  const classes = useStyles();

  const { accessCode } = useParams();

  const { loading, error, data } = useQuery(tippeeWithAccessCodeQuery,
    {
      variables: {
        accessCode: accessCode
      },
    })

  if (loading) 
    return (
      <div>Loading</div>
    )

  if (error) 
    return (
      <div>Error</div>
    )

  function rowColor(type) {
    switch (type) {
      case 'Payout':
        return t4cOrangeColor+'7D'
      case 'Payment':
        return t4cGreenColor+'7D'
      case 'Fee':
        return t4cRedColor+'7D'
      default:
        return t4cPurpleColor+'7D'
    }
  }

  function compare(a, b) {
    return a.timestamp > b.timestamp ? -1 : 1;
  }

  data.tippeeWithAccessCode.transactions.sort(compare);

  const transactions = data.tippeeWithAccessCode.transactions.map((trans) => {
    return [
      'R'+(trans.totalInCents/100).toFixed(2),
      {
        type: 'txType',
        value: trans.type,
        color: rowColor(trans.type),
        class: classes.feeIcon
      },
      moment(trans.timestamp).format('LLL'),
      trans.provider,
      trans.description,
    ]
  })

  return (

    <MobileOptimisedContainer className={classes.container}>
      <PaginatedTable
        tableHead={[
          'Amount',
          'Type',
          'Timestamp',
          'Provider',
          'Description/ID/Token',
        ]}
        tableData={transactions}
        stickyHeadClass={classes.headClass}
        paginationClass={classes.paginationClass}
        initialRowsPerPage={10}
      />
    </MobileOptimisedContainer>
  );
}
