import * as React from 'react';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import {
  ClickAwayListener,
  MenuItem,
  IconButton,
  FormHelperText
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import { TextField } from 'formik-material-ui'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormikPhoneInput from 'components/Misc/FormikPhoneInput'
import PropTypes from 'prop-types';

import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { useMutation, useQuery } from '@apollo/client';

import { CREATE_OR_MODIFY_USER, ROLE_QUERY, LOCATIONS_LIST_QUERY, ORGS_LIST_QUERY, USER_QUERY } from 'gql/queries'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.black,
  },
}));

const NewUserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  mobile: Yup.mixed().test({
    message: 'Invalid mobile',
    test: (value) => {
      if (!value) return true
      const phoneNumber = parsePhoneNumberFromString(value)
      if (!phoneNumber) return false
      return phoneNumber.isValid()
    },
  }),
  email: Yup.string().email('Invalid email').required('Required'),
  role: Yup.string().required('Required'),
});


const AddUserForm = function AddUserForm ({
  onCloseForm,
  entityId,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [createOrModifyUser] = useMutation(CREATE_OR_MODIFY_USER);
  const { loading: loadingRoles, error: rolesError, data: roles } = useQuery(ROLE_QUERY)
  const { loading: loadingLocation, error: locationError, data: locations } = useQuery(LOCATIONS_LIST_QUERY)
  const { loading: loadingOrganisation, error: organisationError, data: organisations } = useQuery(ORGS_LIST_QUERY)
  const { loading: loadingEntity, error: entityError, data: entityQuery } = useQuery(USER_QUERY,
    {
      variables: {
        id: entityId
      },
    })

  const handleClickAway = () => {
    onCloseForm(false)
  }

  if (loadingRoles || loadingLocation || loadingOrganisation || loadingEntity)
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <p>Loading...</p>
        </ClickAwayListener>
      </Dialog>
    )

  if (rolesError || locationError || organisationError || entityError)
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <ClickAwayListener onClickAway={handleClickAway}>
          <p>Error...</p>
        </ClickAwayListener>
      </Dialog>
    )

  const user = entityQuery?.user
  if (user) {
      user.locationIds = user.locations.map( (location) => {
      return location.id
    })
  }

  return(
    <Formik
      initialValues={{
        id: user ? entityId : '',
        name: user ? user.name : '',
        mobile: user ? user.mobile || '' : '',
        email: user ? user.email : '',
        role: user ? user.role : '',
        locationIds: user ? user.locationIds : [],
        organisationId: user ? user.organisationId || '' : '',
      }}
      validationSchema={NewUserSchema}
      onSubmit={(values, { setSubmitting }) => {
        createOrModifyUser({
          variables: {
            data: values
          }
        })
        .then( () => {
          setSubmitting(false)
          onCloseForm(true)
        })
        .catch( (error) => {
          setSubmitting(false)
          enqueueSnackbar(error.message, { 
            variant: 'error',
          });
        })
        
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <div>
          <Form>
            <Dialog open={true} aria-labelledby="form-dialog-title">
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <DialogTitle id="form-dialog-title">
                    {user ? 'Modify' : 'Add'} User
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClickAway}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                  <DialogContentText>
                  {user ? 'Modify a User on' : 'Add a User to'} Tip4Change
                  </DialogContentText>
                    <Field
                      component={TextField}
                      name="name"
                      type="string"
                      label="Name (Required)"
                      fullWidth
                    />
                    <Field
                      component={FormikPhoneInput}
                      name="mobile"
                      type="string"
                      inputExtraProps={{
                        name: 'mobile',
                        label: "Mobile Number (Optional)"
                      }}
                    />
                    <ErrorMessage component={FormHelperText} error={true} name="mobile" />
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      label="Email (Required)"
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      name="role"
                      type="string"
                      select={true}
                      label="User Role (Required)"
                      SelectProps={{
                        MenuProps: {
                          disablePortal: true
                        }
                      }}
                      fullWidth
                    >
                      {roles.roles.map((value) => {
                        return (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        )
                      })}
                    </Field>
                    <Field
                      component={TextField}
                      name="locationIds"
                      type="string"
                      select={true}
                      label="Locations (Optional)"
                      SelectProps={{
                        multiple: true,
                        MenuProps: {
                          disablePortal: true
                        }
                      }}
                      fullWidth
                    >
                      {locations.locations.map((value) => {
                        return (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        )
                      })}
                    </Field>
                    <Field
                      component={TextField}
                      name="organisationId"
                      type="string"
                      select={true}
                      label="Organisation (Optional)"
                      SelectProps={{
                        MenuProps: {
                          disablePortal: true
                        }
                      }}
                      fullWidth
                    >
                      {organisations.organisations.map((value) => {
                        return (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        )
                      })}
                    </Field>
                    
                  </DialogContent>
                  <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {user ? 'MODIFY USER' : 'ADD USER'}
                  </Button>
                  </DialogActions>
                </div>
              </ClickAwayListener>
            </Dialog>
          </Form>
        </div>
      )}
    </Formik>
  );
}

AddUserForm.propTypes = {
  onCloseForm: PropTypes.func.isRequired,
  entityId: PropTypes.string,
};

export default AddUserForm;