// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
// core components
import styles from 'assets/jss/material-dashboard-react/components/tableStyle.js'
import Button from 'components/CustomButtons/Button.js'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(styles)

export default function CustomTable(props) {
  const classes = useStyles()
  const {
    tableHead,
    tableData,
    tableHeaderColor,
    stickyHeadClass,
    clickedRow,
    selected,
    setSelected
  } = props

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n[0].data);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
  
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
  
    setSelected(newSelected);
  };
  
  const isSelected = (id) => selected ? selected.indexOf(id) !== -1 : null

  const numSelected = selected ? selected.length : null
  const rowCount = tableData.length

  return (
    <div className={classes.tableResponsive}>
      <Table stickyHeader className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + 'TableHeader']} classes={{
            root: stickyHeadClass
          }}>
            <TableRow className={classes.tableHeadRow}>
              {selected && 
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </TableCell>}
              {tableHead.map((prop, key) => {
                const hideCell = prop.hideCellOnMobile === true ? classes.hideCellOnMobile : ''
                const heading = typeof prop === 'object' ? prop.text : prop
                return (
                  <TableCell
                    classes={{
                      root: stickyHeadClass
                    }}
                    className={classes.tableCell + ' ' + classes.tableHeadCell + ' ' + hideCell}
                    key={key}
                  >
                    {heading}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((row, key) => {
            return (
              <TableRow key={key} className={classes.tableBodyRow}>
                {row.map((prop, key) => {
                  if (prop === null) {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                      </TableCell>
                    )
                  } else if (typeof prop === 'object' && prop.type === 'button') {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        <Button
                          color="primary"
                          value={prop.id}
                          onClick={(e) => prop.handler(prop.id)}
                        >
                          {prop.child}
                        </Button>
                      </TableCell>
                    )
                  } else if (typeof prop === 'object' && prop.type === 'icon-button') {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        <Button
                          justIcon round
                          color="primary"
                          value={prop.id}
                          onClick={(e) => prop.handler(prop.id)}
                        >
                          {prop.child}
                        </Button>
                      </TableCell>
                    )
                  } else if (typeof prop === 'object' && prop.type === 'img') {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        <img
                          className={prop.class}
                          src={prop.src}
                          alt="Profile"
                          onClick={(e) => prop.handler(prop.id)}
                        />
                      </TableCell>
                    )
                  } else if (typeof prop === 'object' && prop.type === 'txType') {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        <div className={prop.class} style={{backgroundColor: prop.color}}>{prop.value}</div>
                      </TableCell>
                    )
                  } else if (typeof prop === 'object' && prop.type === 'menu') {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        <Button justIcon round color="primary" aria-controls="simple-menu" aria-haspopup="true" data-id={prop.id} onClick={prop.handleClick}>
                          <ExpandMore/>
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={prop.anchorEl.current}
                          keepMounted
                          open={Boolean(prop.anchorEl.current && clickedRow === prop.id)}
                          onClose={prop.handleClick}
                        >
                          {prop.menuItems.map((menuItem, key) => {
                            return (
                              <MenuItem key={key} onClick={() => prop.func(prop.id,menuItem.func)}>
                                <ListItemIcon>
                                  {menuItem.icon}
                                </ListItemIcon>
                                <ListItemText primary={menuItem.name} />
                              </MenuItem>
                            )
                          })}
                        </Menu>
                      </TableCell>
                    )
                  } else if (typeof prop === 'object' && prop.type === 'text') {
                    const hideCell = prop.hideCellOnMobile === true ? classes.hideCellOnMobile : ''
                    return (
                      <TableCell className={classes.tableCell + ' ' + hideCell} key={key}>
                        {prop.text}
                      </TableCell>
                    )
                  } else if (typeof prop === 'object' && prop.type === 'hidden') {
                    // no op
                    return null
                  } else if (typeof prop === 'object' && prop.type === 'id') {
                    const isItemSelected = isSelected(prop.data);
                    const labelId = `enhanced-table-checkbox-${key}`;
                    return (
                      <TableCell padding="checkbox" key={key}>
                        <Checkbox
                          onClick={(event) => handleClick(event, prop.data)}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                    )
                  } else {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        {prop}
                      </TableCell>
                    )
                  }
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray',
}

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  tableHead: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  tableData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
    ),
  ),
}
